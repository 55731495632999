import React, {useState} from 'react';
import ExceptionDetails from './ExceptionDetails';

function AttemptMatching() {
  const [key, setKey] = useState(0);

  const resetComponent = () => {
    setKey(prevKey => prevKey + 1);
  };

  return (
    <div>
      <ExceptionDetails key={key} resetComponent={resetComponent} />
    </div>
  );
}

export default AttemptMatching