import React, { useState } from "react";
import FiltersComponent from "@components/FilterComponent";
import {
  getLocalConfig,
  saveToLocalStorage,
} from "@components/CustomTable/helpers";
import SfmModalStore from "@stores/SfmModalStore";
import { GetSFMDisplay } from "@helpers/helpers";
import { useLocation } from "react-router-dom";

const FiltersWrapper = ({
  filterDisplayValues,
  filters,
  selectedFiltersDisplay,
  store,
  prefix,
  setParentSelectedValues,
  multipleValueColumns,
  setOutStandingRecords,
}) => {
  const COLUMN_FILTER_KEY = `${prefix}_filter`;
  const location = useLocation();

  const [appliedFilters, setAppliedFilters] = useState(
    getLocalConfig(COLUMN_FILTER_KEY, location, {})
  );
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});

  const saveAndApplyFilter = (filters) => {
    saveToLocalStorage(COLUMN_FILTER_KEY, filters);
    setAppliedFilters(filters);
    store.loading = true;
    store.setAppliedFilters(
      filters,
      true,
      (data) => {
        if (data) {
          setOutStandingRecords(data);
        } else {
          const sfmData = GetSFMDisplay(data.SFM);
          SfmModalStore.open(sfmData);
        }
      },
      true
    );
  };

  return (
    <>
      <FiltersComponent
        appliedFilters={appliedFilters}
        updateAppliedFilters={saveAndApplyFilter}
        openFilter={openFilterPopup}
        setOpenFilter={setOpenFilterPopup}
        filterDisplayValues={filterDisplayValues}
        filters={filters}
        selectedFiltersDisplay={selectedFiltersDisplay}
        setSelectedValues={(values) => {
          setSelectedValues(values);
          setParentSelectedValues(values);
        }}
        selectedValues={selectedValues}
        multipleValueColumns={multipleValueColumns}
      />
    </>
  );
};

export default FiltersWrapper;
