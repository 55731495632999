export const MESSAGE_TYPE = ["error", "success", "info", "warning"];

export const MAJ_SUCCESS = "MAJ_SUCCESS";
export const MAJ_SUCCESS_INFO = "MAJ_SUCCESS_INFO";
export const MAJ_SUCCESS_WARN = "MAJ_SUCCESS_WARN";
export const MAJ_VAL_ERR = "MAJ_VAL_ERR";
export const MAJ_ERR = "MAJ_ERR";
export const MessageTypes = {
    ERROR: 0,
    SUCCESS: 1,
    INFO: 2,
    WARNING: 3,
};
