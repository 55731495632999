import React, {forwardRef} from "react";

const Dropdown = forwardRef(({ filter, selectedOption, handleChange, index, defaultValue = "" }) => {
  return (
    <>
    {filter.title && <span className="flex text-sm mb-1 text-gray-500 font-semibold ">
      {filter.title}
    </span>}
    <select
    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
    value={selectedOption?.value || ""}
    onChange={(e) => handleChange(e, filter)}
    name={filter.key}
    tabIndex={index}
    >
      {defaultValue && <option value="" disabled>
        {defaultValue}
      </option>}
      {filter.values.map((option) => (
        <option key={option.value} value={option.value} {...{selected: selectedOption?.value === option.value}}>
          {option.displayName}
        </option>
      ))}
    </select>
    </>
  );
});

Dropdown.displayName = "Dropdown";

export default Dropdown;