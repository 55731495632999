import React, { forwardRef } from "react";
import clsx from "clsx";

const SwitchComponent = forwardRef(
  ({ filter, handleChange, value, index }, ref) => {
    const isChecked = (value[filter.key]?.value || []) === true || (value[filter.key]?.value || []) === "true";

    const onClick = () => {
      handleChange({ target: { checked: !isChecked } }, filter);
    };

    const onKeyDown = (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        onClick();
      }
    };

    return (
      <button
        className="flex items-center py-3"
        onKeyDown={onKeyDown}
        onClick={onClick}
        tabIndex={index + 1}
        ref={ref}
      >
        <span className="ml-3">{filter.name}</span>
        <input
          type="checkbox"
          className="hidden"
          id="switch"
          {...(isChecked && {
            checked: true,
          })}
          tabIndex={-1}
        />
        <label
          htmlFor="switch"
          className={clsx(
            `flex items-center ml-4 cursor-pointer w-10 h-5 rounded-full p-1 transition duration-300 ease-in-out pointer-events-none`,
            isChecked ? "bg-[#c6cdff]" : "bg-gray-400"
          )}
          tabIndex={-1}
        >
          <span
            className={clsx(
              `block w-4 h-4 rounded-full bg-white shadow-md transform duration-300 ease-in-out`,
              isChecked ? "translate-x-4" : ""
            )}
          />
        </label>
      </button>
    );
  }
);

SwitchComponent.displayName = "SwitchComponent";

export default SwitchComponent;
