import React from "react";
import { flexRender } from "@tanstack/react-table";
import PopupComponent from "@components/PopupComponent";
import { filter, CopyToCliboard } from "./helpers";
import { FormatWithCommas } from "@helpers/helpers";
import clsx from "clsx";

const MAX_CHARACTER_TRUNCATE = 16;

const renderCell = (
  row,
  cell,
  popupOpen,
  setPopupOpen,
  appliedFilters,
  saveAndApplyFilter,
  translate
) => {

  const { column } = cell;
  const columnDef = column.columnDef;
  const context = cell.getContext();
  const value = context.getValue();
  const passedFilterCheck =
    !columnDef.filterCheck ||
    (columnDef.filterCheck && columnDef.filterCheck(value));
  const truncate = columnDef.truncate;
  const cellHasPopupOpen = popupOpen === context.cell.id;
  const shouldShowTruncatePopup =
    // truncate && !!value && value.length >= parseInt(columnDef.size / 7);
    truncate &&
    !!value &&
    value.length >= parseInt(columnDef.size / MAX_CHARACTER_TRUNCATE);

  const passedActionCheck =
    columnDef.cellAction &&
    (!columnDef.cellActionCheck ||
      (columnDef.cellActionCheck && columnDef.cellActionCheck(value, context)));
  return cell.getIsGrouped() ? (
    // If it's a grouped cell, add an expander and row count
    <>
      <button
        {...{
          onClick: row.getToggleExpandedHandler(),
          style: {
            cursor: row.getCanExpand() ? "pointer" : "normal",
          },
        }}
      >
        {row.getIsExpanded() ? "👇" : "👉"}{" "}
        {flexRender(columnDef.cell, context)} ({row.subRows.length})
      </button>
    </>
  ) : cell.getIsPlaceholder() ? null : (columnDef.canFilter ||
      shouldShowTruncatePopup) && // Otherwise, just render the regular cell // For cells with repeated values, render null
    passedFilterCheck ? (
    <>
      <div
        className={`w-full cursor-pointer text-purple-400 hover:text-purple-600 font-semibold flex items-center justify-center relative`}
      >
        <div
          className={clsx(
            `absolute z-[2] w-full h-full left-0 top-0 p-0 bg-inherit transition-visibility bg-opacity-80 flex-row`,
            cellHasPopupOpen ? "flex" : "hidden",
            `group-hover:flex items-center justify-center gap-3`
          )}
        >
          {columnDef.cellAction &&
            (!columnDef.cellActionCheck ||
              (columnDef.cellActionCheck &&
                columnDef.cellActionCheck(value, context))) && (
              <svg
                data-tooltip-target="tooltip-default"
                onClick={() => {
                  columnDef.cellAction(value, context);
                }}
                title={columnDef.cellActionHelper}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 hover:scale-110 hover:text-purple-800 transition-all duration-200"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
                />
              </svg>
            )}
          {columnDef.displayInPopup && (
            <>
              {popupOpen === context.cell.id && (
                <PopupComponent
                  open={true}
                  cellData={context}
                  value={value}
                  setPopupOpen={setPopupOpen}
                />
              )}
              <svg
                onClick={() => {
                  setPopupOpen(context.cell.id);
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 hover:scale-110 hover:text-purple-800 transition-all duration-200"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </>
          )}

          {columnDef.canFilter && (
            <div title="Filter by this value">
              <svg
                onClick={() => {
                  filter(
                    columnDef,
                    value,
                    row.original,
                    appliedFilters,
                    saveAndApplyFilter,
                    translate
                  );
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 hover:scale-110 hover:text-purple-800 transition-all duration-200"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
                />
              </svg>
            </div>
          )}
          {!columnDef.hideCopy && <CopyToCliboard value={value} />}
          {shouldShowTruncatePopup && (
            <div title="Show full text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 hover:scale-110 hover:text-purple-800 transition-all duration-200"
                onClick={() => setPopupOpen(context.cell.id)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </div>
          )}
        </div>
        <div
          className={clsx(
            columnDef.extraClass,
            truncate && "truncate overflow-visible justify-start",
            ((passedFilterCheck && columnDef.canFilter) ||
              shouldShowTruncatePopup) &&
              "group-hover:opacity-20"
          )}
        >
          {columnDef.splitCellValue ? (
            <span className="text-sm">
              {value?.split(columnDef.splitCellValue)[0]}
              <br />
              {value?.split(columnDef.splitCellValue)[1]}
            </span>
          ) : columnDef.showThousandSeperator && !columnDef.cell ? (
            <span>{FormatWithCommas(value)}</span>
          ) : (
            flexRender(columnDef.cell, context)
          )}
        </div>
      </div>
      {cellHasPopupOpen && shouldShowTruncatePopup && (
        <PopupComponent
          open={popupOpen}
          rawValue={
            columnDef.splitCellValue ? (
              <span className="text-sm">
                <b className="text-md">
                  {value?.split(columnDef.splitCellValue)[0]}
                </b>
                <br />
                {value?.split(columnDef.splitCellValue)[1]}
              </span>
            ) : (
              value
            )
          }
          setPopupOpen={setPopupOpen}
          addBlur
        />
      )}
    </>
  ) : passedFilterCheck && columnDef.displayInPopup ? (
    <PopupComponent
      open={cellHasPopupOpen}
      cellData={context}
      value={value}
      setPopupOpen={setPopupOpen}
    />
  ) : (
    <div
      className={clsx(truncate && "truncate overflow-visible justify-start")}
    >
      <div
        onClick={() => {
          passedActionCheck && columnDef.cellAction(value, context);
        }}
        className={clsx(
          `font-sans flex items-center justify-center group text-purple-400 font-semibold`,
          passedActionCheck && "cursor-pointer hover:text-purple-600",
          passedFilterCheck && columnDef.canFilter && "group-hover:opacity-20"
        )}
      >
        {columnDef.splitCellValue ? (
          <span className="text-sm">
            {value?.split(columnDef.splitCellValue)[0]}
            <br />
            {value?.split(columnDef.splitCellValue)[1]}
          </span>
        ) : columnDef.showThousandSeperator && !columnDef.cell ? (
          <span>{FormatWithCommas(value)}</span>
        ) : (
          flexRender(columnDef.cell, context)
        )}{" "}
        <svg
          data-tooltip-target="tooltip-default"
          title={columnDef.cellActionHelper}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={clsx(
            `size-6 opacity-0 hidden group-hover:text-purple-800 transition-all duration-200`,
            passedActionCheck && "group-hover:opacity-100  group-hover:block"
          )}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
          />
        </svg>
      </div>
    </div>
  );
};

export default renderCell;
