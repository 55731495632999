import React, { useState } from "react";

const ExpandedViewRow = ({ row, context, actionFunctions }) => {
  const [expandDescription, setExpandeDescription] = useState(false);
  const description =
    "Loremz Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

  const lastFiveRuns = [
    {
      BusinessDay: "2024-4-9",
      BDInstance: "1.00",
      IsManualTrigger: true,
      IsScheduleTrigger: false,
      ReconciliationRunStatusTag: "Completed",
      ReconciliationRunStatusName: "COMPLETED",
      TotalInTransitRecords: 3,
      TotalExceptionRecords: 2,
    },
    {
      BusinessDay: "2025-11-11",
      BDInstance: "1.00",
      IsManualTrigger: false,
      IsScheduleTrigger: true,
      ReconciliationRunStatusTag: "Rolled Back",
      ReconciliationRunStatusName: "ROLLED_BACK",
      TotalInTransitRecords: 5,
      TotalExceptionRecords: 1,
    },
    {
      BusinessDay: "2025-11-11",
      BDInstance: "1.00",
      IsManualTrigger: false,
      IsScheduleTrigger: true,
      ReconciliationRunStatusTag: "Rolled Back",
      ReconciliationRunStatusName: "ROLLED_BACK",
      TotalInTransitRecords: 5,
      TotalExceptionRecords: 1,
    },
    {
      BusinessDay: "2025-11-11",
      BDInstance: "1.00",
      IsManualTrigger: false,
      IsScheduleTrigger: true,
      ReconciliationRunStatusTag: "Rolled Back",
      ReconciliationRunStatusName: "ROLLED_BACK",
      TotalInTransitRecords: 5,
      TotalExceptionRecords: 1,
    },
    {
      BusinessDay: "2025-11-11",
      BDInstance: "1.00",
      IsManualTrigger: false,
      IsScheduleTrigger: true,
      ReconciliationRunStatusTag: "Rolled Back",
      ReconciliationRunStatusName: "ROLLED_BACK",
      TotalInTransitRecords: 5,
      TotalExceptionRecords: 1,
    },
  ];

  const lastFiveRunsConfig = {
    BusinessDay: 80,
    BDInstance: 30,
    IsManualTrigger: 50,
    IsScheduleTrigger: 50,
    ReconciliationRunStatusTag: 50,
    TotalInTransitRecords: 40,
    TotalExceptionRecords: 60,
  };

  const MAX_DESCRIPTION_LENGTH = 200;

  return (
    <div className="flex items-center justify-start w-full text-left">
      <div className="w-full md:w-[calc(100vw-260px)] relative md:sticky left-0 pl-14 flex gap-20 justify-start md:justify-between mb-2">
        <div className="flex flex-col items-start justify-start">
          <span className="text-lg font-semibold">Quick overview:</span>
          <span className="text-sm max-w-md">
            {expandDescription
              ? description
              : `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`}
          </span>
          <span
            className="text-xs font-semibold cursor-pointer mt-2"
            onClick={() => {
              setExpandeDescription(!expandDescription);
            }}
          >
            Read More
          </span>
          <span className="text-lg font-semibold mt-5 mb-1">
            Quick Actions:
          </span>
          <div className="flex gap-3">
            {row.original.Actions.map((action, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    actionFunctions(action, row);
                  }}
                  className="w-max border border-gray-300 text-xs text-start hover:bg-gray-100 font-normal cursor-pointer p-2 px-4"
                >
                  {action.replace(/_/g, " ")}
                </button>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col min-w-[264px] w-1/2 pr-4 transition-all bg-primary-dark text-[#777f9e] text-14">
          <span className="text-lg font-semibold">Last 5 Runs</span>
          <hr className="mt-[8px] mb-[5px]"></hr>
          <div className="flex flex-row w-full justify-between gap-2 my-2">
            <div className="flex gap-4 w-full justify-between">
              <span
                className="py-[2px] whitespace-nowrap text-xs font-semibold overflow-hidden"
                style={{ width: `${lastFiveRunsConfig.BusinessDay}px` }}
              >
                Business Day
              </span>
              <span
                className="py-[2px] whitespace-nowrap text-xs font-semibold overflow-hidden"
                style={{
                  width: `${lastFiveRunsConfig.ReconciliationRunStatusTag}px`,
                }}
              >
                Status
              </span>
              <span
                className="py-[2px] whitespace-nowrap text-xs font-semibold overflow-hidden"
                style={{
                  width: `${lastFiveRunsConfig.TotalInTransitRecords}px`,
                }}
              >
                Transit
              </span>
              <span
                className="py-[2px] whitespace-nowrap text-xs font-semibold overflow-hidden"
                style={{
                  width: `${lastFiveRunsConfig.TotalExceptionRecords}px`,
                }}
              >
                Exceptions
              </span>
            </div>
          </div>
          {lastFiveRuns &&
            lastFiveRuns.map((singleRun, index) => (
              <div
                key={index}
                className="flex flex-row w-full justify-between gap-2 my-2"
              >
                <div className="flex gap-4 w-full justify-between">
                  <span
                    className="py-[2px] whitespace-nowrap text-xs"
                    style={{ width: `${lastFiveRunsConfig.BusinessDay}px` }}
                  >
                    {singleRun.BusinessDay} ({singleRun.BDInstance})
                  </span>
                  <span
                    className="py-[2px] whitespace-nowrap text-xs"
                    style={{
                      width: `${lastFiveRunsConfig.ReconciliationRunStatusTag}px`,
                    }}
                  >
                    {singleRun.ReconciliationRunStatusTag}
                  </span>
                  <span
                    className="py-[2px] text-blue-500 cursor-pointer whitespace-nowrap text-xs text-center"
                    style={{
                      width: `${lastFiveRunsConfig.TotalInTransitRecords}px`,
                    }}
                  >
                    {singleRun.TotalInTransitRecords}
                  </span>
                  <span
                    className="py-[2px] text-blue-500 cursor-pointer whitespace-nowrap text-xs text-center"
                    style={{
                      width: `${lastFiveRunsConfig.TotalExceptionRecords}px`,
                    }}
                  >
                    {singleRun.TotalExceptionRecords}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ExpandedViewRow;
