import React from "react";
import CustomTable from "@components/CustomTable/table";

import { TableProvider } from "@components/CustomTable/context";

const CustomTableWrapper = (props) => {
  return (
    <TableProvider tableProps={props}>
      <CustomTable {...props} />
    </TableProvider>
  );
};

export default CustomTableWrapper;