import React, { useEffect, useState } from "react";
import myStore from "./store";
import CustomTable from "@components/CustomTable";
import { Observer } from "mobx-react";
import {
  getDataAcquisitionInstanceLog,
  fetchDynamicFilters,
  transformData,
  GetReconciliationFile,
  GetSFMDisplay,
  RevokeDataAcquisitionInstance,
  GetRevocableDataTotals,
  resolveStatusColumn,
  addToDownloadCenter,
} from "@helpers/helpers";
import InfoModalStore from "@stores/InfoModalStore";
import SfmModalStore from "@stores/SfmModalStore";
import DownloadModalStore from "@stores/DownloadModalStore";
import Confirmationpopup from "@components/ConfirmationPopup";
import LegendComponent from "@components/LegendComponent";

import { useTranslation } from "react-i18next";

const DEFAULT_MONTH_RANGE = 6;
const TRIM_CURRENT_DATE = 16;

const DataAcquisitionsInstances = () => {
  const { t: translate, i18n } = useTranslation();

  // const statusColors = {
  //   COMPLETED: "bg-green-400",
  //   INPROGRESS: "bg-gray-400",
  //   COMPLETED_WARNING: "bg-orange-400",
  //   FAILED: "bg-red-400",
  //   PARTIALLY_REVOKED: "bg-orange-700",
  //   REVOKED: "bg-red-900",
  // };

  const [currentDateTime, setCurrentDateTime] = useState("");
  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [revocableStats, setRevocableStats] = useState({});
  useEffect(() => {
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - DEFAULT_MONTH_RANGE);

    const formattedDateTime = today.toISOString().slice(0, TRIM_CURRENT_DATE);
    setCurrentDateTime(formattedDateTime);
    fetchDynamicFilters("DAInstanceFilters", (newFilters) => {
      setDynamicFilters(newFilters);
    });
  }, []);

  const pinningInitialState = ["pin", "DataAcquisitionInstanceStatusName"];

  const legendData = [
    {
      text: translate("data-acquisition-instances.legend.completedWithWarning"),
      color: "#f6ad55",
      tag: "COMPLETED_WARNING",
    },
    {
      text: translate("data-acquisition-instances.legend.partiallyRevoked"),
      color: "#dd6b20",
      tag: "PARTIALLY_REVOKED",
    },
    {
      text: translate("data-acquisition-instances.legend.revoked"),
      color: "#742a2a",
      tag: "REVOKED",
    },
  ];

  const columns = [
    {
      accessorKey: "DataAcquisitionName",
      header: translate(
        "data-acquisition-instances.columns.DataAcquisitionName"
      ),
      canFilter: true,
      isMultiple: false,
      sortable: true,
      size: 250,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.DataAcquisitionTag;
      },
      processFilterKey: (value, row) => {
        return "DataAcquisitionTag";
      },
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "triggerType",
      header: translate("data-acquisition-instances.columns.Trigger"),
      sortable: false,
      size: 180,
      isMultiple: true,
      canFilter: false,
      processFilterKey: (value, row) => {
        return "triggerType";
      },
      processFilterValue: (value, row) => {
        if (row.IsFileDrop) return "FileDrop";
        if (row.IsScheduleTrigger) return "Schedule";
        if (row.IsManualTrigger) return "Manual";
      },
      cell: ({ row }) => {
        return (
          <span
            className={`rounded-full flex items-end justify-center gap-1 w-full font-normal text-sm border border-r-10 border-transparent `}
          >
            {row.original.IsFileDrop && (
              <span>
                {translate("data-acquisition-instances.legend.fileDrop")}
              </span>
            )}
            {row.original.IsScheduleTrigger && (
              <span>
                {translate("data-acquisition-instances.legend.scheduled")}
              </span>
            )}
            {row.original.IsManualTrigger && (
              <span>
                {translate("data-acquisition-instances.legend.manual")}
              </span>
            )}
          </span>
        );
      },
    },
    {
      accessorKey: "StartDate",
      header: translate("data-acquisition-instances.columns.StartDate"),
      size: 210,
      sortable: true,
      truncate: true,
      canFilter: true,
      filterType: "date",
      splitCellValue: "T",
    },
    {
      accessorKey: "EndDate",
      header: translate("data-acquisition-instances.columns.EndDate"),
      size: 130,
      sortable: true,
      truncate: true,
      canFilter: true,
      filterType: "date",
      splitCellValue: "T",
    },
    {
      accessorKey: "DataAcquisitionInstanceStatusName",
      header: translate("data-acquisition-instances.columns.Status"),
      headerHelper: () => {
        return <LegendComponent data={legendData} />;
      },
      size: 50,
      sortable: true,
      canFilter: true,
      // hideHeader: true,
      hideCopy: true,
      isMultiple: true,
      processFilterValue: (value, row) => {
        return row.DataAcquisitionInstanceStatusTag;
      },
      processFilterKey: (value, row) => {
        return "DataAcquisitionInstanceStatusTag";
      },
      filterType: "checkbox",
      operator: "EQ",
      cell: ({ row }) => {
        return resolveStatusColumn(
          row.original.DataAcquisitionInstanceStatusName,
          row.original.DataAcquisitionInstanceStatusTag
        );
      },
    },
    {
      accessorKey: "TotalRawRecords",
      header: translate("data-acquisition-instances.columns.TotalRawRecords"),
      size: 190,
      isMultiple: false,
      sortable: false,
      truncate: false,
      hideCopy: true,
      showThousandSeperator: true,
    },
    {
      accessorKey: "TotalRecordsForMatching",
      header: translate(
        "data-acquisition-instances.columns.TotalRecordsForMatching"
      ),
      size: 250,
      isMultiple: false,
      sortable: false,
      truncate: false,
      hideCopy: true,
      showThousandSeperator: true,
    },
    {
      accessorKey: "RecordsRange",
      header: translate("data-acquisition-instances.columns.RecordsRange"),
      size: 150,
      isMultiple: false,
      sortable: false,
      truncate: false,
      hideCopy: true,
    },
  ];

  const multipleValueColumns = columns
    .filter((column) => column.isMultiple)
    .map((column) => column.accessorKey);

  const filterDisplayValues = {
    triggerType: [
      {
        displayName: translate("filters-display.Manual"),
        value: "Manual",
        selectable: true,
      },
      {
        displayName: translate("filters-display.Schedule"),
        value: "Schedule",
        selectable: true,
      },
      {
        displayName: translate("filters-display.FileDrop"),
        value: "FileDrop",
        selectable: true,
      },
    ],
    DataAcquisitionInstanceStatusTag: [
      {
        displayName: translate("filters-display.All"),
        value: null,
        selectable: false,
        children: [
          {
            displayName: translate("filters-display.InProgress"),
            value: "IN_PROGRESS",
          },
          {
            displayName: translate("filters-display.Completed"),
            value: "COMPLETED",
          },
          {
            displayName: translate("filters-display.CompletedWarning"),
            value: "COMPLETED_WARNING",
          },
          {
            displayName: translate("filters-display.Failed"),
            value: "FAILED",
          },
        ],
      },
    ],
  };

  const getDynamicFilters = (key, onlyChildren = false) => {
    return transformData(
      (!!dynamicFilters &&
        dynamicFilters.length > 0 &&
        dynamicFilters.find((filter) => filter.ColumnName === key)?.Values) ||
        [],
      onlyChildren
    );
  };
  const filters = [
    {
      mainFilters: [
        {
          key: "DataAcquisitionName",
          displayName: translate(
            "data-acquisition-instances.filters.acquisition"
          ),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("data-acquisition-instances.filters.acquisition"),
              type: "title",
              key: "title",
            },
            {
              name: translate(
                "data-acquisition-instances.filters.acquisitionStreamStatus"
              ),
              title: translate(
                "data-acquisition-instances.filters.acquisitionStreamStatus"
              ),
              type: "checkboxGroup",
              searchable: false,
              key: "DataAcquisitionInstanceStatusTag",
              values: getDynamicFilters("DataAcquisitionInstanceStatusTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate(
                "data-acquisition-instances.filters.acquisitionInstance"
              ),
              type: "checkboxGroup",
              searchable: false,
              key: "DataAcquisitionTag",
              values: getDynamicFilters("DataAcquisitionTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "Acquisition",
          displayName: translate("data-acquisition-instances.filters.date"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          ),
          components: [
            {
              name: translate("data-acquisition-instances.filters.selectDate"),
              type: "title",
              key: "title",
            },
            {
              name: translate("data-acquisition-instances.filters.startDate"),
              type: "dateInput",
              inputType: "date",
              key: "StartDate",
              placeHolder: translate(
                "data-acquisition-instances.filters.startDate"
              ),
              prefix: "",
              defaultValue: currentDateTime,
              filterType: "date",
            },
            {
              name: translate("data-acquisition-instances.filters.endDate"),
              type: "dateInput",
              inputType: "date",
              key: "EndDate",
              placeHolder: translate(
                "data-acquisition-instances.filters.endDate"
              ),
              prefix: "",
              defaultValue: currentDateTime,
              filterType: "date",
            },
          ],
        },
      ],
    },
  ];

  const selectedFiltersDisplay = {
    direction: translate("filters-display.Direction"),
    triggerType: translate("filters-display.TriggerType"),
    DataAcquisitionName: translate("filters-display.Stream"),
    DataAcquisitionInstanceStatusTag: translate("filters-display.Status"),
    ScheduleDate: translate("filters-display.ScheduleDate"),
    StartDate: translate("filters-display.StartDate"),
    EndDate: translate("filters-display.EndDate"),
    RecordsRange: translate("filters-display.RecordsRange"),
    TotalRecordsForMatching: translate(
      "filters-display.TotalRecordsForMatching"
    ),
    TotalRawRecords: translate("filters-display.TotalRawRecords"),
    contains: translate("filters-display.Contains"),
    startWith: translate("filters-display.StartsWith"),
    DataAcquisitionTag: translate("filters-display.DataAcquisitionName"),
  };

  const actions = {
    VIEW_DETAILS: {
      actionTypeTag: "VIEW_DETAILS",
      name: translate("data-acquisition-instances.actions.VIEW_DETAILS"),
      actionFN: (row, ids, selectedReason, reasonDescription) => {
        getDataAcquisitionInstanceLog({
          DataAcquisitionInstanceId: row.original.DataAcquisitionInstanceId,
          callback: (data) => {
            if (data.DataAcquisitionLog !== null) {
              InfoModalStore.open({
                type: "Acquisition_Instances_Info",
                title: translate(
                  "data-acquisition-instances.titles.INSTANCE_INFO"
                ),
                data: data.DataAcquisitionLog,
                show: true,
              });
            } else {
              const sfmData = GetSFMDisplay(data.SFM);
              SfmModalStore.open({
                DetailedMessage: translate("shared.no-details-message"),
                Message: "SUCCESS",
                SfmTag: "SFM_SUCCESS",
                Type: 1,
                ShowMessage: true,
              });
            }
          },
          store: myStore,
        });
      },
      confirmation: false,
    },
    REVOKE: {
      actionTypeTag: "REVOKE",
      name: translate("data-acquisition-instances.actions.REVOKE"),
      actionFN: (row) => {
        GetRevocableDataTotals(
          row.original.DataAcquisitionInstanceId,
          (data) => {
            const TotalPromotedRecords = data.TotalPromotedRecords;
            const TotalRevocableRecords = data.TotalRevocableRecords;
            setRevocableStats({
              DataAcquisitionInstanceId: row.original.DataAcquisitionInstanceId,
              TotalPromotedRecords,
              TotalRevocableRecords,
              message: translate(
                "data-acquisition-instances.confirmation-messages.REVOKE",
                {
                  TotalRevocableRecords,
                  TotalPromotedRecords,
                }
              ),
            });
            setOpenConfirmation(true);
          },
          myStore
        );
      },
    },
    DOWNLOAD_RAW_DATA: {
      actionTypeTag: "DOWNLOAD_RAW_DATA",
      name: translate("data-acquisition-instances.actions.DOWNLOAD_RAW_DATA"),
      actionFN: (row) => {
        myStore.loading = true;
        GetReconciliationFile(
          "DataAcquisition",
          "DATA_ACQUISITION_RAW_DATA",
          "DataAcquisitionInstanceId",
          row.original.DataAcquisitionInstanceId.toString(),
          (data) => {
            if (data.Files[0]) {
              const newPendingID = addToDownloadCenter({
                href: data.Files[0],
                activityTag: "Raw Data",
                done: true,
              });
              window.open(data.Files[0], "_blank");
              DownloadModalStore.open(500);
              DownloadModalStore.highlight(500, newPendingID);
            }
          },
          myStore
        );
      },
      confirmationMessage: translate(
        "data-acquisition-instances.confirmation-messages.DOWNLOAD_RAW_DATA"
      ),
      confirmation: true,
    },

    DOWNLOAD_FAULTY_RECORDS: {
      actionTypeTag: "DOWNLOAD_FAULTY_RECORDS",
      name: translate(
        "data-acquisition-instances.actions.DOWNLOAD_FAULTY_RECORDS"
      ),
      actionFN: (row) => {
        myStore.loading = true;
        GetReconciliationFile(
          "DataAcquisition",
          "DATA_ACQUISITION_FAULTY_DATA",
          "DataAcquisitionInstanceId",
          row.original.DataAcquisitionInstanceId.toString(),
          (data) => {
            if (data.Files[0]) {
              const newPendingID = addToDownloadCenter({
                href: data.Files[0],
                activityTag: "Faulty Data",
                done: true,
              });
              window.open(data.Files[0], "_blank");
              DownloadModalStore.open(500);
              DownloadModalStore.highlight(500, newPendingID);
            }
          },
          myStore
        );
      },
      confirmationMessage: translate(
        "data-acquisition-instances.confirmation-messages.DOWNLOAD_FAULTY_RECORDS"
      ),
      confirmation: true,
    },
    DOWNLOAD_REVOKED_RECORDS: {
      actionTypeTag: "DOWNLOAD_REVOKED_DATA",
      name: translate(
        "data-acquisition-instances.actions.DOWNLOAD_REVOKED_RECORDS"
      ),
      actionFN: (row) => {
        myStore.loading = true;
        GetReconciliationFile(
          "DataAcquisition",
          "DATA_ACQUISITION_REVOKED_DATA",
          "DataAcquisitionInstanceId",
          row.original.DataAcquisitionInstanceId.toString(),
          (data) => {
            if (data.Files[0]) {
              const newPendingID = addToDownloadCenter({
                href: data.Files[0],
                activityTag: "Revoked Data",
                done: true,
              });
              window.open(data.Files[0], "_blank");
              DownloadModalStore.open(500);
              DownloadModalStore.highlight(500, newPendingID);
            }
          },
          myStore
        );
      },
      confirmationMessage: translate(
        "data-acquisition-instances.confirmation-messages.DOWNLOAD_REVOKED_DATA"
      ),
      confirmation: true,
    },
  };

  return (
    <Observer>
      {() => {
        return (
          <>
            <CustomTable
              store={myStore}
              loading={myStore.loading}
              columnsWithoutPin={columns}
              pinCondition={(row) => {
                return false;
              }}
              filterDisplayValues={filterDisplayValues}
              selectedFiltersDisplay={selectedFiltersDisplay}
              filters={filters}
              pinningInitialState={pinningInitialState}
              setParentSelectedValues={() => {}}
              actions={actions}
              prefix="DataAcquisitionsInstances"
              title={translate("data-acquisition-instances.title")}
              multipleValueColumns={multipleValueColumns}
              pinnedRowHeight={45}
              maxTableHeight={"calc(100dvh - 400px)"}
              language={i18n.language}
            />
            {/* {!myStore.loading && <div className="container mx-auto">
              <div className="mx-auto w-[90%] pt-5">
                <LegendComponent data={legendData} inline />
              </div>
            </div>} */}
            {openConfirmation && Object.keys(revocableStats).length > 0 && (
              <Confirmationpopup
                singleAction={{
                  actionFN: () =>
                    RevokeDataAcquisitionInstance(
                      revocableStats.DataAcquisitionInstanceId,
                      revocableStats.TotalPromotedRecords,
                      revocableStats.TotalRevocableRecords,
                      (data) => {
                        const sfmData = GetSFMDisplay(data.SFM);
                        SfmModalStore.open(sfmData, () => {
                          window.location.href = "/data-acquisitions-instances";
                        });
                      },
                      myStore
                    ),
                  confirmation: true,
                  confirmationMessage: revocableStats.message,
                  severity: "high",
                }}
                setOpenConfirmationAction={setOpenConfirmation}
              />
            )}
          </>
        );
      }}
    </Observer>
  );
};
export default DataAcquisitionsInstances;
