import React, { createContext, useState } from "react";

export const TableContext = createContext();

export const TableProvider = ({ children }) => {
  const [submenuVisible, setSubmenuVisible] = useState(null);

  return (
    <TableContext.Provider
      value={{
        submenuVisible,
        setSubmenuVisible,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => React.useContext(TableContext);
