import React, { Component } from "react";

import { Observer } from "mobx-react";
import InfoModalStore from "@stores/InfoModalStore";
import InfoComponent from "@components/InfoComponent";
import clsx from "clsx";

export default class InfoModal extends Component {
  render() {
    const getContent = (type) => {
      switch (type) {
        case "Acquisition_Instances_Info":
          const hideKeys = [
            "/DataAcquisitionTag",
            "/DataAcquisitionPromotion/StatusTag",
            "/StatusTag",
            "/basicInfo/DataAcquisitionTag",
            "/basicInfo/RetryCount",
            "/basicInfo/Feedback",
            "/basicInfo/DataAcquisitionDescription",
            "/basicInfo/PeriodicityTag",
            "/basicInfo/StatusTag",
            "/DataAcquisitionPromotion/DataPromotionFeedback",
            "/DataAcquisitionFetching/DataFetchingFeedback",
            "/DataAcquisitionFetching/RawDataFMSId",
            "/DataAcquisitionFetching/StatusTag",
            "/DataAcquisitionRevocations/StatusTag",
            "/DataAcquisitionRevocations/RevokedDataFMSId",
            "/DataAcquisitionRevocations/DataRevocationFeedback",
          ];
          return (
            <InfoComponent data={InfoModalStore.data} hideKeys={hideKeys} />
          );
        case "Reconciliation_Run_Info":
          const hideKeysRun = [
            "/ReconciliationRunPromotion/StatusTag",
            "/basicInfo/StatusTag",
            "/RecordsMatching/StatusTag",
            "/RecordsMatching/MatchingResultsFMSId",
            "/RecordsMatching/RecordsMatchingFeedback",
            "/RecordsFetching/StatusTag",
            "/SourceRecordsFetching/SourceRecordsFetchingFeedback",
            "/SourceRecordsFetching/ReconciliationStreamSideTag",
            "/DestinationRecordsFetching/DestinationRecordsFetchingFeedback",
            "/DestinationRecordsFetching/ReconciliationStreamSideTag",
            "/basicInfo/ReconciliationRunTag",
            "/basicInfo/ReconciliationStreamTag",
            "/RecordsMatching/StatusTag",
            "/basicInfo/PeriodicityTag",
            "/basicInfo/Feedback",
            "/basicInfo/BDInstance",
            "/basicInfo/PeriodicityName",
            "/ReconciliationRunPromotion/DataPromotionFeedback",
            "/ReconciliationRunFetching/DataFetchingFeedback",
            "/ReconciliationRollback/StatusTag",
            "/ReconciliationRollback/RollbackReportingFeedback",
            "/ReconciliationRollback/RollbackResultsFMSId",
            "/ReconciliationRollback/ArchivedCorrectiveActions"
          ];
          return (
            <InfoComponent data={InfoModalStore.data} hideKeys={hideKeysRun} />
          );
        default:
          return <></>;
      }
    };
    return (
      <Observer>
        {
          () => (
            // InfoModalStore.showing && (
            <div
              className={clsx(
                `group`,
                InfoModalStore.showing ? "visible" : "invisible"
              )}
            >
              <div
                className="fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-20 z-[25]"
                onClick={() => InfoModalStore.close()}
              />
              <div
                className={clsx(
                  `fixed transition-all overflow-y-scroll h-dvh duration-500 ease-in-out z-50 bg-white text-black py-6 px-5 top-0`,
                  InfoModalStore.showing
                    ? "ltr:right-0 rtl:left-0  min-w-[55%]"
                    : "ltr:-right-36 rtl:-left-36  min-w-0"
                )}
              >
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3 justify-center items-center">
                    <div className="w-full font-bold">
                      {" "}
                      {InfoModalStore.title}
                    </div>
                    <button
                      onClick={() => InfoModalStore.close()}
                      className="w-full justify-end flex"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-8 hover:text-red-800 transition-all duration-200"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col gap-2">
                    {getContent(InfoModalStore.type)}
                  </div>
                </div>
              </div>
            </div>
          )
          // )
        }
      </Observer>
    );
  }
}
