import React from "react";
import icons from "@assets/icons";

export const SVG = ({ size = 4, className = "", icon = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={`${className} size-${size}`}
    >
      {icons[icon]}
    </svg>
  );
};
