import React, { useEffect, useState, useRef } from "react";
import FilterComponent from "@components/FilterComponent";
import OrderingComponent from "@components/OrderingComponent";
import SortingComponent from "@components/SortingComponent";
import ActionCell from "./actionCell";
import renderCell from "./renderCell";
import PinnedRow from "./pinnedRow";
import { directions } from "@helpers/helpers";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import SfmModalStore from "@stores/SfmModalStore";
import DownloadModalStore from "@stores/DownloadModalStore";

import { useTranslation } from "react-i18next";

import Loader from "@components/LoaderComponent/loader";
import { fetchDynamicTags, addToDownloadCenter, updateDownloadItem, GetSFMDisplay } from "@helpers/helpers";
import {
  scrollFn,
  getCommonPinningStyles,
  getLocalConfig,
  saveToLocalStorage,
  stopScroll,
  scrollHeaderIntoView,
  handleClearSort,
  handleSort,
  handleHideColumn,
  setHTMLFontSize,
  openFullScreen,
  closeFullScreen,
  checkFullScreen,
} from "./helpers";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { ExportReconciliationRecords } from "@helpers/helpers";

import ButtonComponent from "@components/ButtonComponent";

import KeyboardEventHandler, {
  displayName,
} from "react-keyboard-event-handler";

import Confirmationpopup from "@components/ConfirmationPopup";

import {
  TableContext,
  TableProvider,
  useTableContext,
} from "@components/CustomTable/context";
import { toast } from "react-toastify";

const highlightedColor = "#fbfbfb";

/**
 * CustomTable component represents a customizable table with various features such as pinning, filtering, sorting, and pagination.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.columnsWithoutPin - An array of column configurations without pinning.
 * @param {Object} props.store - The store object containing data and methods for managing the table state.
 * @param {Function} props.pinCondition - A function that determines whether a row should be pinnable based on its data.
 * @param {Array} props.filterDisplayValues - An array of display values for the filter options.
 * @param {Array} props.selectedFiltersDisplay - An array of selected filter display values.
 * @param {Array} props.filters - An array of filter configurations.
 * @param {Object} props.pinningInitialState - The initial pinning state for columns.
 * @param {Function} props.setParentSelectedValues - A function to set the selected values in the parent component.
 * @param {Object} props.actions - An object containing action functions for the table.
 * @param {string} [props.prefix="table"] - The prefix for the table component.
 * @param {string} props.title - The title of the table.
 * @param {number} [props.pinnedRowHeight=45] - The height of pinned rows.
 * @param {number} [props.headerHeight=58] - The height of the table header.
 * @param {Function} [props.expandedViewRow=()=>{}] - A function to render the expanded view of a row.
 * @param {number} [props.maxTableHeight=500] - The maximum height of the table.
 * @param {Array} [props.multipleValueColumns=[]] - An array of column keys that support multiple values.
 * @param {boolean} [props.loading] - A flag indicating whether the table is in a loading state.
 * @param {boolean} [props.showFilter=true] - A flag indicating whether to show the filter options.
 * @returns {JSX.Element} The rendered CustomTable component.
 */

const DEFAULT_PINNED_ROW_HEIGHT = 45;
const DEFAULT_HEADER_HEIGHT = 45;
const DEFAULT_MAX_TABLE_HEIGHT = 500;
const MINIMUM_ROW_PINNING = 10;
const CustomTable = ({
  language = "en",
  columnsWithoutPin,
  store = null,
  pinCondition,
  allowRowPinning = true,
  filterDisplayValues,
  selectedFiltersDisplay,
  filters,
  pinningInitialState,
  setParentSelectedValues,
  actions,
  prefix = "table",
  title,
  pinnedRowHeight = DEFAULT_PINNED_ROW_HEIGHT,
  headerHeight = DEFAULT_HEADER_HEIGHT,
  expandedViewRow = () => {},
  rowSeparator = () => {},
  maxTableHeight = DEFAULT_MAX_TABLE_HEIGHT,
  multipleValueColumns = [],
  loading,
  showFilter = true,
  selectableCondition = false,
  GlobalActions = {},
  additionalComponent,
  externalData = [],
  minimalTable,
  hideActionColumn,
  noData,
  isExpandable,
  secondaryTitle,
  autoFillData = true,
  additionalComponentPosition = "middle",
  setExternalSelectedState,
  rowCustomID,
  customSubRows,
  hidePinColumn,
  initialTableVisibility = true,
  showExportButton = false,
  activityTag = "",
  showAccessibility = false,
  externalDisplays,
  hideActionOnRow,
}) => {
  // const { data: storeData, limit } = store;
  const { t: translate, i18n } = useTranslation();
  const storeData = !!store ? store.data : null;
  const limit = !!store ? store.limit : 0;
  const data = storeData || externalData || [];

  const location = useLocation();

  const findDisplayName = (externalDisplays, value) => {
    let displayName = null;
    externalDisplays.map((singleDisplay) => {
      if (singleDisplay.children) {
        singleDisplay.children.map((child) => {
          if (child.value === value) {
            displayName = child.displayName;
          } else if (value.includes(child.value)) {
            displayName = displayName
              ? `${displayName}, ${child.displayName}`
              : child.displayName;
          }
        });
      }
    });
    return displayName;
  };

  const allowedUrlFilters = [
    "ReconciliationStreamTag",
    "IsInTransitCritical",
    "AgingTierTag",
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.toString() && !minimalTable) {
      // Check if there are any params in the URL
      const filters = {};

      for (const [key, value] of params.entries()) {
        if (allowedUrlFilters.includes(key)) {
          filters[key] = {
            displayName: findDisplayName(externalDisplays, value) || "",
            value: value,
            operator: "EQ",
            filterType:
              value === "true" || value === "false" ? "boolean" : "checkbox",
          };
        }
        saveAndApplyFilter(filters);
      }
    }
  }, [externalDisplays]);
  const uniquePinCondition = data.some((row) => pinCondition(row));
  const shouldHidePinColumn =
    !uniquePinCondition &&
    !selectableCondition &&
    data.length <= MINIMUM_ROW_PINNING;

  const PinCell = ({
    row,
    includeLeafRows,
    includeParentRows,
    pinCondition,
    expandedRows,
    setExpandedRows,
    selectableCondition,
  }) => {
    const rowSelected = row.getIsSelected();
    const shouldHidePin = hidePinColumn;

    return shouldHidePin ? null : row.getIsPinned() ? (
      <div className="inline-flex w-full justify-center">
        <button
          onClick={() => row.pin(false, includeLeafRows, includeParentRows)}
        >
          ❌
        </button>
      </div>
    ) : (
      <div className="inline-flex flex-col w-full justify-between gap-2">
        {row.getCanPin() && allowRowPinning && (
          <button
            className="hover:scale-125"
            onClick={() => row.pin("top", includeLeafRows, includeParentRows)}
            tabIndex={-1}
          >
            ⤴
          </button>
        )}
        {((pinCondition && pinCondition(row.original)) || !pinCondition) && (
          <button
            className={clsx(
              `hover:scale-125 text-gray-400 hover:text-gray-400`,
              expandedRows[row.id] &&
                "text-gray-600 hover:text-gray-600 scale-125 hover:scale-100"
            )}
            tabIndex={-1}
            onClick={() => {
              // add row to expanded state
              setExpandedRows((prev) => ({
                ...prev,
                [row.id]: prev[row.id] ? !prev[row.id] : true,
              }));
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
              />
            </svg>
          </button>
        )}
        {selectableCondition && selectableCondition(row.original) && (
          <input
            type="checkbox"
            className="form-checkbox text-blue-500 h-4 w-4 cursor-pointer"
            checked={rowSelected}
            onChange={row.getToggleSelectedHandler()}
          />
        )}
      </div>
    );
  };

  const pinColumn = {
    id: "pin",
    accessorKey: "pin",
    header: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        selectableCondition &&
        data.length > 0 &&
        selectableCondition(null, true) && (
          <div className="flex flex-col items-center gap-2">
            <input
              type="checkbox"
              className="form-checkbox text-blue-500 h-4 w-4 cursor-pointer"
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
            {selectedCount > 0 && <span>{selectedCount}</span>}
          </div>
        )
      );
    },
    className: "text-center",
    size: 43,
    canPin: () => true,
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <PinCell
          key={row.id + data.length}
          row={row}
          includeLeafRows={includeLeafRows}
          includeParentRows={includeParentRows}
          pinCondition={pinCondition}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          selectableCondition={selectableCondition}
        />
      );
    },
  };

  const actionColumn = () => {
    return {
      accessorKey: "Actions",
      header: translate("shared.actions"),
      canPin: () => true,
      size: 75,
      enableHiding: false,
      width: 200,
      cell: ({ row }) => {
        return (
          <ActionCell
            key={`${submenuVisible}_${row.id}`}
            row={row}
            toggleSubMenu={() => toggleSubMenu(row.id)}
            subMenuID={submenuVisible}
            actionFunctions={actionFunctions}
            actions={actions}
            store={store}
            hideActionOnRow={hideActionOnRow}
          />
        );
      },
    };
  };
  // eslint-disable-next-line no-undef
  const FE_VERSION = VERSION || 1;
  const COLUMN_INITIAL_ORDER_KEY = `${FE_VERSION}_${prefix}_${language}_initialOrder`;
  const COLUMN_ORDER_KEY = `${FE_VERSION}_${prefix}_${language}_ordering`;
  const COLUMN_SORT_KEY = `${FE_VERSION}_${prefix}_${language}_sorting`;
  const COLUMN_VISIBILITY_KEY = `${FE_VERSION}_${prefix}_${language}_visibility`;
  const COLUMN_FILTER_KEY = `${FE_VERSION}_${prefix}_${language}_filter`;

  const DEFAULT_FONT_SIZE = 16;
  const MIN_FONT_SIZE = 12;
  const MAX_FONT_SIZE = 20;

  const includeLeafRows = false;
  const includeParentRows = false;

  const [selectedValues, setSelectedValues] = useState({});
  // const [submenuVisible, setSubmenuVisible] = useState(false);
  const { submenuVisible, setSubmenuVisible } = useTableContext();
  const [isFullScreen, setIsFullScreen] = useState(checkFullScreen());
  const [expandedRows, setExpandedRows] = useState({});
  const [selectableRows, setSelectableRows] = useState({});
  const isRtl = language === "ar";

  useEffect(() => {
    if (shouldHidePinColumn) {
      if (hideActionColumn) {
        setColumns([...resolveMidColumnsWithoutPin()]);
      } else {
        setColumns([...resolveMidColumnsWithoutPin(), actionColumn()]);
      }
    } else {
      if (hideActionColumn) {
        setColumns([pinColumn, ...resolveMidColumnsWithoutPin()]);
      } else {
        setColumns([
          pinColumn,
          ...resolveMidColumnsWithoutPin(),
          actionColumn(),
        ]);
      }
    }
  }, [data, submenuVisible]);

  const resolveMidColumnsWithoutPin = () => {
    const cols = isRtl
      ? [].concat(columnsWithoutPin).reverse()
      : columnsWithoutPin;
    return cols;
  };

  const [columns, setColumns] = useState(
    hideActionColumn
      ? hidePinColumn
        ? [...resolveMidColumnsWithoutPin()]
        : [pinColumn, ...resolveMidColumnsWithoutPin()]
      : [pinColumn, ...resolveMidColumnsWithoutPin(), actionColumn()]
  );
  const [columnPinning, setColumnPinning] = useState({
    left: isRtl ? ["Actions"] : pinningInitialState || ["pin"],
    right: isRtl ? pinningInitialState || ["pin"] : ["Actions"],
  });

  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 3000,
  });

  const [rowPinning, setRowPinning] = useState({
    top: [],
    bottom: [],
  });

  const initialPins = ["pin", "actions"];

  const [appliedFilters, setAppliedFilters] = useState(
    getLocalConfig(COLUMN_FILTER_KEY, location)
  );
  const [appliedSort, setAppliedSort] = useState(
    getLocalConfig(COLUMN_SORT_KEY, location)
  );

  const [columnVisibility, setColumnVisibility] = useState(
    getLocalConfig(COLUMN_VISIBILITY_KEY, location)
  );
  const [columnOrder, setColumnOrder] = useState(
    (!getLocalConfig(COLUMN_ORDER_KEY, location).includes(undefined) &&
      getLocalConfig(COLUMN_ORDER_KEY, location).length > 0 &&
      getLocalConfig(COLUMN_ORDER_KEY, location)) ||
      getLocalConfig(COLUMN_INITIAL_ORDER_KEY, location)
  );
  const [initialColumnOrder, setInitialColumnOrder] = useState(
    getLocalConfig(COLUMN_INITIAL_ORDER_KEY, location)
  );

  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [dynamicTags, setDynamicTags] = useState([]);

  const [openOrderingPopup, setOpenOrderingPopup] = useState(false);
  const [openSortingPopup, setOpenSortingPopup] = useState(false);

  const [prepareApiData, setPrepareApiData] = useState({});
  const [openConfirmationAction, setOpenConfirmationAction] = useState(false);

  const [popupOpen, setPopupOpenFn] = useState(null);
  const [currentFontSize, setCurrentFontSize] = useState(DEFAULT_FONT_SIZE);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [expandedChildren, setExpandedChildren] = useState({});

  const [tableVisible, setTableVisible] = useState(initialTableVisibility);

  const setPopupOpen = (id) => {
    setPopupOpenFn(id);
    document.body.style.overflow = id ? "hidden" : "auto";
  };
  const resetInitialState =
    JSON.stringify(getLocalConfig(COLUMN_ORDER_KEY, location)) !==
    JSON.stringify(getLocalConfig(COLUMN_INITIAL_ORDER_KEY, location));
  const [showResetButton, setShowResetButton] = useState(resetInitialState);

  const noPopupsOpen = openFilterPopup || openOrderingPopup || openSortingPopup;

  const selectableRowsKeys = Object.keys(selectableRows);

  const toggleSubMenu = (id) => {
    id !== "bulk" && table.resetRowSelection(true);
    setSubmenuVisible(id);
    setColumns(
      shouldHidePinColumn
        ? [...resolveMidColumnsWithoutPin(), actionColumn()]
        : [pinColumn, ...resolveMidColumnsWithoutPin(), actionColumn()]
    );

    
  };

  const actionFunctions = (action, row) => {
    if (action.confirmation) {
      setPrepareApiData({ action: action, row, null: null });
      setOpenConfirmationAction(true);
    } else {
      if (store) {
        store.loading = action?.hideLoading ? false : true;
      }
      action.actionFN(row);
    }
    handleClickOutside();
  };
  const bulkActionFunctions = (action, rowIDs) => {
    setPrepareApiData({ action: action, null: null, rowIDs });
    setOpenConfirmationAction(true);
  };

  const onColumnOrderChange = (newOrder) => {
    if (initialColumnOrder.length === 0) {
      setInitialColumnOrder(newOrder);
    }
    setColumnOrder(newOrder);
  };

  const setExternalSelectableRows = (props) => {
    setSelectableRows(props);
    setExternalSelectedState && setExternalSelectedState(props);
  };

  const table = useReactTable({
    data,
    columns: columns.map((column) => {
      return {
        ...column,
        enableHiding: !(
          columnPinning.left.includes(column.accessorKey) ||
          columnPinning.right.includes(column.accessorKey)
        ),
      };
    }),
    state: {
      globalFilter,
      columnVisibility,
      pagination: pagination,
      rowPinning,
      columnPinning,
      columnOrder,
      rowSelection: selectableRows,
      expanded: expandedChildren,
    },
    getRowId: (row) =>
      !!rowCustomID ? rowCustomID(row) : row.MatchingRecordId,
    enableRowSelection: true,
    enableRowPinning: data.length > MINIMUM_ROW_PINNING,
    onRowSelectionChange: setExternalSelectableRows, //hoist up the row selection state to your own scope
    onColumnVisibilityChange: setColumnVisibility,
    onColumnPinningChange: setColumnPinning,
    onRowPinningChange: setRowPinning,
    keepPinnedRows: true,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onColumnOrderChange: onColumnOrderChange,
    getSubRows: (row) => (!!customSubRows ? customSubRows(row) : row.subRows),
    onExpandedChange: setExpandedChildren,
  });

  useEffect(() => {
    const initialVisibility = {};
    table.getAllLeafColumns().forEach((column) => {
      initialVisibility[column.id] = column.getIsVisible();
    });
    setColumnVisibility(initialVisibility);
  }, [table]);

  let scrollTimeout = null;
  const SCROLL_DELAY = 50;

  const scroll = (direction, withDelay = true) => {
    if (scrollTimeout !== null) {
      clearTimeout(scrollTimeout);
    }

    if (withDelay) {
      scrollTimeout = setInterval(() => {
        scrollFn(".main-table-container", direction);
      }, SCROLL_DELAY); // 500ms debounce time
    } else {
      scrollFn(".main-table-container", direction, 100);
    }
  };

  const handleClickOutside = () => {
    setSubMenuOpen(false);
    setSubmenuVisible(null);
    SfmModalStore.close();
    setColumns(
      shouldHidePinColumn
        ? [...resolveMidColumnsWithoutPin(), actionColumn()]
        : [pinColumn, ...resolveMidColumnsWithoutPin(), actionColumn()]
    );
  };
  const scrollUpRef = useRef(null);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const columnOrder = table.getAllLeafColumns().map((column) => column.id);
    // setColumnOrder(columnOrder);
    setInitialColumnOrder(columnOrder);
    !getLocalConfig(COLUMN_INITIAL_ORDER_KEY, location, null) &&
      saveAndApplyOrdering(columnOrder, COLUMN_INITIAL_ORDER_KEY);

    store && store.setAppliedFilters(appliedFilters, false);
    store && store.setAppliedSort(appliedSort, false);
    store && autoFillData && store.fillData();
    const tableContainer = tableContainerRef.current;

    autoFillData &&
      fetchDynamicTags("Lists", ["CORRECTIVE_ACTION_REASONS"], (newTags) => {
        setDynamicTags(newTags);
      });
  }, []);

  const resetColumnOrder = () => {
    table.resetColumnOrder(false);
    table.resetColumnVisibility();
    saveAndApplyOrdering(
      getLocalConfig(COLUMN_INITIAL_ORDER_KEY, location) || initialColumnOrder
    );
  };

  const saveAndApplySort = (sort) => {
    setAppliedSort(sort);
    store.setAppliedSort(sort);
    saveToLocalStorage(COLUMN_SORT_KEY, sort);
  };

  const saveAndApplyOrdering = (order, localStorageKey = COLUMN_ORDER_KEY) => {
    setColumnOrder(order);
    saveToLocalStorage(localStorageKey, order);
  };

  const saveAndApplyFilter = (filters) => {
    saveToLocalStorage(COLUMN_FILTER_KEY, filters);
    setAppliedFilters(filters);
    store?.setAppliedFilters(filters);
  };

  const saveOrderingConfig = (id, value) => {
    let visibilityObject = getLocalConfig(COLUMN_VISIBILITY_KEY, location, {});
    if (id === null) {
      saveToLocalStorage(COLUMN_VISIBILITY_KEY, value);
    } else {
      visibilityObject[id] = value;
      saveToLocalStorage(COLUMN_VISIBILITY_KEY, visibilityObject);
    }
  };

  const handleKeyPress = (key, e) => {
    const openOrderPopupOnly = !openFilterPopup && !openSortingPopup;
    const openFilterPopupOnly = !openOrderingPopup && !openSortingPopup;
    const openSortingPopupOnly = !openFilterPopup && !openOrderingPopup;

    if (key === "f" && openFilterPopupOnly) {
      setOpenFilterPopup(!openFilterPopup);
    } else if (key === "c" && openOrderPopupOnly) {
      setOpenOrderingPopup(!openOrderingPopup);
    } else if (key === "s" && openSortingPopupOnly) {
      setOpenSortingPopup(!openSortingPopup);
    } else if (
      key === "up" ||
      key === "down" ||
      key === "left" ||
      key === "right"
    ) {
      e.preventDefault();
      scroll(key, false);
    } else if (key === "esc") {
      closeFullScreen();
      setIsFullScreen(false);
    }
  };

  const renderAccessibility = () => {
    return (
      <div className="flex gap-2">
        <button
          onClick={() => {
            setHTMLFontSize(
              "decrease",
              setCurrentFontSize,
              currentFontSize,
              DEFAULT_FONT_SIZE,
              MAX_FONT_SIZE,
              MIN_FONT_SIZE
            );
          }}
          className="text-gray-600 disabled:text-gray-200 disabled:cursor-not-allowed"
          disabled={currentFontSize === MIN_FONT_SIZE}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[25px] h-[25px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM13.5 10.5h-6"
            />
          </svg>
        </button>
        <button
          onClick={() => {
            setHTMLFontSize(
              "default",
              setCurrentFontSize,
              currentFontSize,
              DEFAULT_FONT_SIZE,
              MAX_FONT_SIZE,
              MIN_FONT_SIZE
            );
          }}
          className="text-gray-600 disabled:text-gray-200 disabled:cursor-not-allowed"
          disabled={currentFontSize === DEFAULT_FONT_SIZE}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[25px] h-[25px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </button>
        <button
          onClick={() => {
            setHTMLFontSize(
              "increase",
              setCurrentFontSize,
              currentFontSize,
              DEFAULT_FONT_SIZE,
              MAX_FONT_SIZE,
              MIN_FONT_SIZE
            );
          }}
          className="text-gray-600 disabled:text-gray-200 disabled:cursor-not-allowed"
          disabled={currentFontSize === MAX_FONT_SIZE}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[25px] h-[25px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"
            />
          </svg>
        </button>
      </div>
    );
  };

  const hasData = data.length > 0;
  const translatedDirections = directions(translate);
  return (
    <div>
      {loading && (
        <div className="fixed top-0 left-0 w-screen h-dvh bg-black opacity-50 flex justify-center items-center z-50">
          <Loader />
        </div>
      )}
      {openConfirmationAction && (
        <Confirmationpopup
          actions={actions}
          prepareApiData={prepareApiData}
          afterClose={() => handleClickOutside()}
          setOpenConfirmationAction={setOpenConfirmationAction}
          setPrepareApiData={setPrepareApiData}
          dynamicTags={dynamicTags}
          store={store}
        />
      )}
      <KeyboardEventHandler
        handleKeys={["f", "c", "s", "up", "down", "left", "right", "esc"]}
        onKeyEvent={(key, e) => handleKeyPress(key, e)}
        className={clsx(
          "HOCKeyboardHandler",
          loading && store.data.length === 0 && "hidden"
        )}
      >
        <div
          className={`${
            !minimalTable ? "container my-5" : "my-0"
          } mx-auto w-[calc(100%-${
            minimalTable ? "0px" : "110px"
          })] text-black fill-gray-400`}
        >
          <div className="w-[92%] mx-auto flex flex-col">
            <div className="flex justify-between">
              {title && (
                <div
                  className={clsx(
                    minimalTable && "cursor-pointer",
                    "flex items-center gap-2"
                  )}
                  {...(minimalTable && {
                    onClick: () => setTableVisible(!tableVisible),
                  })}
                >
                  <p
                    className={clsx(
                      minimalTable
                        ? " text-md font-semibold"
                        : "text-2xl font-normal"
                    )}
                  >
                    {title}
                    {minimalTable && secondaryTitle && (
                      <span className="text-sm text-gray-500 ml-2">
                        {`${secondaryTitle}`}
                      </span>
                    )}
                  </p>
                  {isExpandable && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={clsx(
                        `size-5 transition-all duration-500`,
                        tableVisible && "rotate-180"
                      )}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 15.75 7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  )}
                </div>
              )}
              {(!minimalTable || showAccessibility) && renderAccessibility()}
            </div>
            {additionalComponent &&
              additionalComponentPosition === "top" &&
              React.createElement(additionalComponent)}
            {!minimalTable && (
              <>
                <div className="flex w-full mb-5 mt-5">
                  {(hasData || appliedFilters) && (
                    <div
                      className={clsx(
                        showFilter && (hasData || appliedFilters)
                          ? "justify-between"
                          : "justify-end",
                        "flex w-full gap-4 items-baseline"
                      )}
                    >
                      {" "}
                      {showFilter && (hasData || appliedFilters) && (
                        <FilterComponent
                          appliedFilters={appliedFilters}
                          updateAppliedFilters={saveAndApplyFilter}
                          openFilter={openFilterPopup}
                          setOpenFilter={setOpenFilterPopup}
                          filterDisplayValues={filterDisplayValues}
                          filters={filters}
                          selectedFiltersDisplay={selectedFiltersDisplay}
                          setSelectedValues={(values) => {
                            setSelectedValues(values);
                            setParentSelectedValues(values);
                          }}
                          selectedValues={selectedValues}
                          multipleValueColumns={multipleValueColumns}
                        />
                      )}
                      {additionalComponent &&
                        additionalComponentPosition === "middle" &&
                        React.createElement(additionalComponent)}
                      <OrderingComponent
                        table={table}
                        showResetButton={showResetButton}
                        setShowResetButton={setShowResetButton}
                        setColumnOrder={saveAndApplyOrdering}
                        columnOrder={columnOrder}
                        resetColumnOrder={() => resetColumnOrder()}
                        saveOrderingConfig={saveOrderingConfig}
                        columnVisibilityKey={COLUMN_VISIBILITY_KEY}
                        openFilter={openOrderingPopup}
                        setOpenFilter={setOpenOrderingPopup}
                      />
                      {/* <SortingComponent
                        setAppliedSort={saveAndApplySort}
                        appliedSort={appliedSort}
                        columns={columns.filter(
                          (column) => column.header !== "Status"
                        )}
                        openFilter={openSortingPopup}
                        setOpenFilter={setOpenSortingPopup}
                      /> */}
                    </div>
                  )}
                </div>
                <div className="flex justify-between mb-5">
                  {showExportButton &&
                    Object.keys(appliedFilters).length > 0 && (
                      <ButtonComponent
                        className="text-sm min-w-max h-fit rounded-lg"
                        // withoutBorder={true}
                        id="options-menu"
                        color="blue"
                        onClick={() => {
                          const toastId = toast.loading(translate('download-center.actions.processing-export'))
                          const newPendingID = addToDownloadCenter({
                            href: "-",
                            activityTag: activityTag || translate('download-center.actions.default-export-activity'),
                            done: false,
                          });
                          // store.loading = true;
                          ExportReconciliationRecords(
                            store.filter,
                            store.sort,
                            activityTag,
                            (data) => {
                              if (data.File) {
                                updateDownloadItem({
                                  id: newPendingID,
                                  newProps: { 
                                    href: data.File, 
                                    done: true,
                                    title: `${data.File.split("/").pop()}`
                                  },
                                });
                                toast.update(toastId, { render: translate('download-center.actions.download-ready'), type: "success", isLoading: false, autoClose: 3000 });
                                DownloadModalStore.open(500);
                                DownloadModalStore.highlight(500, newPendingID);
                                window.open(data.File, "_blank");
                              } else{
                                const sfmData = GetSFMDisplay(data.SFM);
                                toast.update(toastId, { render: `${sfmData.Message}`, type: "warning", isLoading: false, autoClose: 5000 });
                              }
                            },
                            store,
                            toastId,
                            translate('download-center.actions.download-ready')
                          );
                        }}
                        ref={null}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                            />
                          </svg>
                        }
                        text="Export"
                      />
                    )}
                </div>
                <div className="flex flex-col gap-4 items-end">
                  {appliedSort.length > 0 && (
                    <div
                      key={JSON.stringify(appliedSort)}
                      className="flex flex-col items-end text-sm"
                    >
                      {appliedSort.map((singleSort, index) => {
                        return (
                          <div
                            key={index}
                            className=" mb-2 text-gray-500 flex items-center text-sm"
                          >
                            {index === 0
                              ? translate("shared.sorting")
                              : translate("shared.then")}{" "}
                            {translate("shared.by")}
                            <b className="mx-2 border-b border-gray-400 text-gray-600">
                              {singleSort.name}
                            </b>
                            <div
                              title={
                                translatedDirections[singleSort.direction]
                                  .display
                              }
                            >
                              {translatedDirections[singleSort.direction].icon}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            className={clsx(
              minimalTable &&
                `grid grid-rows-[0fr] transition-all ease-in-out duration-500`,
              tableVisible ? "grid-rows-[1fr] opacity-100" : "opacity-0"
            )}
          >
            <div className={clsx(minimalTable && "overflow-hidden")}>
              <div
                className={clsx(
                  `w-[92%] mx-auto relative main-table-parent-container`,
                  minimalTable ? "my-5" : "my-10"
                )}
                dir="ltr"
              >
                {!minimalTable && (
                  <>
                    <button
                      className="absolute -top-10 left-1/2 text-2xl hover:scale-120 hover:animate-[ping_1.5s_linear_infinite] text-gray-400 hover:text-gray-700 cursor-none"
                      onMouseEnter={() => scroll("up")}
                      onClick={() => scroll("up", false)}
                      onMouseOut={() => stopScroll(scrollTimeout)}
                      ref={scrollUpRef}
                      tabIndex={noPopupsOpen ? -1 : 4}
                    >
                      ↑
                    </button>
                    <button
                      className="absolute -left-10 top-1/2 text-2xl hover:scale-120 hover:animate-[ping_1.5s_linear_infinite] text-gray-400 hover:text-gray-700 cursor-none"
                      onMouseEnter={() => scroll("left")}
                      onClick={() => scroll("left", false)}
                      onMouseOut={() => stopScroll(scrollTimeout)}
                      tabIndex={noPopupsOpen ? -1 : 3}
                    >
                      ←
                    </button>
                  </>
                )}

                {noData && (data.length === 0 || externalData.length === 0) ? (
                  noData(translate || null)
                ) : (
                  <div
                    className="w-full max-w-full overflow-auto main-table-container"
                    ref={tableContainerRef}
                    style={{ maxHeight: maxTableHeight }}
                  >
                    {submenuVisible && (
                      <div
                        className="fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-20 z-[4]"
                        onClick={handleClickOutside}
                      />
                    )}
                    <table className="border border-gray-700 w-full text-center  relative text-sm text-[#777f9e]">
                      <thead
                        className={clsx(
                          `sticky -top-0`,
                          submenuVisible || popupOpen ? "z-[1]" : "z-[3]"
                        )}
                        // style={{ boxShadow: "0px 0px 2px #e1e1e1" }}
                        style={{
                          boxShadow: "0px -7px 4px 9px #e1e1e1",
                          backgroundColor: highlightedColor,
                        }}
                      >
                        {table.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => {
                              const shouldHideHeader =
                                header.column.columnDef.hideHeader;
                              const { column } = header;
                              const includedInitialPins =
                                initialPins.includes(
                                  header.column.columnDef.accessorKey
                                ) || header.column.columnDef.hidePin;

                              const columnHeader =
                                header.column.columnDef.header;
                              const columnHeaderHelper =
                                header.column.columnDef.headerHelper;

                              const isPinnedLeft =
                                header.column.getIsPinned() === "left";

                              const handleSubMenuToggle = (headerId) => {
                                let TOP_OFFSET = 30;
                                let LEFT_OFFSET = 20;
                                setTimeout(() => {
                                  const parent = document.getElementById(
                                    `parent-${header.id}`
                                  );
                                  const child = document.getElementById(
                                    `child-${header.id}`
                                  );
                                  if (child) {
                                    const parentRect =
                                      parent.getBoundingClientRect();

                                    const top = parentRect.top;
                                    const left = parentRect.left;
                                    if (i18n.language === "ar") {
                                      // LEFT_OFFSET = -1 * child.offsetWidth;
                                      TOP_OFFSET = 15;
                                      child.style.right = `unset`;
                                    }
                                    child.style.top = `${top + TOP_OFFSET}px`;
                                    child.style.left = `${
                                      left + LEFT_OFFSET
                                    }px`;
                                  }
                                }, 0);
                                setSubMenuOpen(
                                  subMenuOpen === headerId ? null : headerId
                                );
                                scrollHeaderIntoView(
                                  `header_${header.id}`,
                                  isPinnedLeft,
                                  isRtl
                                );
                              };

                              //Sub Menu Items

                              const currentColumnSorting = appliedSort.find(
                                (sort) => sort.id === header.id
                              );
                              const currentColumnSortingDirection =
                                currentColumnSorting?.direction;
                              const subMenuItems = [
                                {
                                  icon: () => (
                                    <span className="text-lg">
                                      {!isPinnedLeft ? "📌" : "❌"}
                                    </span>
                                  ),
                                  displayText: !isPinnedLeft
                                    ? translate("shared.pinLeft", {
                                        columnHeader,
                                      })
                                    : translate("shared.unpin", {
                                        columnHeader,
                                      }),
                                  // disabled: header.column.getIsPinned() === "left",
                                  onClick: () => {
                                    header.column.pin(
                                      isPinnedLeft
                                        ? false
                                        : isRtl
                                        ? "left"
                                        : "left"
                                    );
                                    setSubMenuOpen(null);
                                  },
                                  hasSeperator: true,
                                  visible:
                                    !includedInitialPins &&
                                    (header.column.columnDef.canPin ===
                                      undefined ||
                                      header.column.columnDef.canPin === true),
                                },
                                {
                                  icon: () => (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                                      />
                                    </svg>
                                  ),
                                  displayText: translate("shared.removeSort", {
                                    columnHeader,
                                  }),
                                  disabled: !currentColumnSorting, // Example: Disable if no sort applied for this header
                                  onClick: () =>
                                    handleClearSort(
                                      header.id,
                                      appliedSort,
                                      saveAndApplySort,
                                      setAppliedSort,
                                      setSubMenuOpen
                                    ),
                                  hasSeperator: false,
                                  visible: header.column.columnDef.sortable,
                                },
                                {
                                  icon: () => (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"
                                      />
                                    </svg>
                                  ),
                                  displayText: translate("shared.sortByAsc", {
                                    columnHeader,
                                  }),
                                  disabled:
                                    currentColumnSortingDirection === "asc", // Example: Disable if no ascending sort applied for this header
                                  onClick: () =>
                                    handleSort(
                                      header,
                                      "asc",
                                      appliedSort,
                                      saveAndApplySort,
                                      setAppliedSort,
                                      setSubMenuOpen
                                    ),
                                  hasSeperator: false,
                                  visible: header.column.columnDef.sortable,
                                },
                                {
                                  icon: () => (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25"
                                      />
                                    </svg>
                                  ),
                                  // displayText: `Sort by ${columnHeader} descending`,
                                  displayText: translate("shared.sortByDesc", {
                                    columnHeader,
                                  }),
                                  disabled:
                                    currentColumnSortingDirection === "desc", // Example: Disable if no descending sort applied for this header
                                  onClick: () =>
                                    handleSort(
                                      header,
                                      "desc",
                                      appliedSort,
                                      saveAndApplySort,
                                      setAppliedSort,
                                      setSubMenuOpen
                                    ),
                                  hasSeperator: true,
                                  visible: header.column.columnDef.sortable,
                                },
                                {
                                  icon: () => (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                                      />
                                    </svg>
                                  ),
                                  displayText: `Hide ${columnHeader} column`,
                                  disabled: false,
                                  onClick: () =>
                                    handleHideColumn(
                                      header,
                                      saveOrderingConfig,
                                      setSubMenuOpen,
                                      setShowResetButton
                                    ),
                                  hasSeperator: false,
                                  visible: true,
                                },
                              ];

                              return (
                                <th
                                  key={header.id}
                                  id={`header_${header.id}`}
                                  colSpan={header.colSpan}
                                  className={clsx(
                                    `relative px-3.5 py-2 text-center border shadow-inner-remove min-w-fit font-semibold`,
                                    column.getIsPinned() &&
                                      "after:content[''] after:absolute after:left-[-1px] after:top-0 after:w-[calc(100%+2px)] after:h-full after:bg-white after:z-[-1] after:opacity-70"
                                  )}
                                  //IMPORTANT: This is where the magic happens!
                                  style={{
                                    ...getCommonPinningStyles(
                                      column,
                                      0,
                                      highlightedColor,
                                      null,
                                      null,
                                      subMenuOpen === header.id ? 2 : 1
                                    ),
                                    verticalAlign: "top",
                                    // whiteSpace:'nowrap',
                                    // display: 'inline-block',
                                    width: column.getSize(),
                                    minWidth: column.getSize(),
                                  }}
                                >
                                  <div className="flex items-center justify-center gap-2">
                                    {!shouldHideHeader && (
                                      <div
                                        id={`parent-${header.id}`}
                                        className={clsx(
                                          `mt-0 flex items-center gap-1`,
                                          !minimalTable && "cursor-pointer"
                                        )}
                                        onClick={() =>
                                          !minimalTable &&
                                          handleSubMenuToggle(header.id)
                                        }
                                      >
                                        {header.isPlaceholder
                                          ? null
                                          : flexRender(
                                              columnHeader,
                                              header.getContext()
                                            )}{" "}
                                        {currentColumnSortingDirection ===
                                          "desc" && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="size-3 text-gray-700"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                                            />
                                          </svg>
                                        )}
                                        {currentColumnSortingDirection ===
                                          "asc" && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="size-3 text-gray-700"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                            />
                                          </svg>
                                        )}
                                      </div>
                                    )}

                                    {!includedInitialPins && (
                                      <div className="flex relative">
                                        {subMenuOpen === header.id && (
                                          <div
                                            className="fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-20  z-[2]"
                                            onClick={handleClickOutside}
                                          />
                                        )}
                                        <div
                                          id={`child-${header.id}`}
                                          className={clsx(
                                            `fixed rtl:right-[unset] ltr:left-0 top-full bg-white p-1 rounded shadow-lg w-max z-[5] popup`,
                                            subMenuOpen === header.id
                                              ? "visible"
                                              : "hidden"
                                          )}
                                        >
                                          <>
                                            {subMenuItems.map(
                                              (subItem, index) => {
                                                return subItem.visible ? (
                                                  <>
                                                    <button
                                                      className={clsx(
                                                        `flex items-center gap-2 p-2 mb-2 w-full hover:bg-gray-100 font-normal`,
                                                        subItem?.disabled
                                                          ? "cursor-default opacity-40"
                                                          : "cursor-pointer"
                                                      )}
                                                      onClick={() =>
                                                        subItem.onClick(
                                                          header,
                                                          subItem?.direciton
                                                        )
                                                      }
                                                      disabled={
                                                        subItem.disabled
                                                      }
                                                    >
                                                      {subItem.icon && (
                                                        <div>
                                                          {subItem.icon()}
                                                        </div>
                                                      )}
                                                      {subItem.displayText.replace(
                                                        "${0}",
                                                        columnHeader
                                                      )}
                                                    </button>
                                                    {subItem.hasSeperator && (
                                                      <div className="border-t-2 mb-4 border-gray-100"></div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                );
                                              }
                                            )}
                                          </>
                                        </div>
                                      </div>
                                    )}
                                    {columnHeaderHelper && (
                                      <div>{columnHeaderHelper()}</div>
                                    )}
                                  </div>
                                </th>
                              );
                            })}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getTopRows() &&
                          table
                            .getTopRows()
                            .map((row, i) => (
                              <PinnedRow
                                key={row.id}
                                id={`row-top-${row.id}`}
                                row={row}
                                table={table}
                                index={i}
                                headerHeight={headerHeight}
                                pinnedRowHeight={pinnedRowHeight}
                                highlightedColor={highlightedColor}
                                submenuVisible={submenuVisible}
                                popupOpen={popupOpen}
                                setPopupOpen={setPopupOpen}
                                appliedFilters={appliedFilters}
                                saveAndApplyFilter={saveAndApplyFilter}
                              />
                            ))}
                        {table.getCenterRows().length ? (
                          table.getCenterRows().map((row, i) => {
                            const rowExpanded = expandedRows[row.id];
                            const rowSelected = selectableRows[row.id];
                            return (
                              <>
                                <tr
                                  key={row.id}
                                  id={`row-center-${row.id}`}
                                  className={clsx(
                                    i % 2 === 0 ? "bg-white" : "bg-gray-100",
                                    submenuVisible === row.id &&
                                      "relative z-[5]"
                                  )}
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    const { column } = cell;
                                    const columnDef = cell.column.columnDef;
                                    const context = cell.getContext();
                                    const value = context.getValue();
                                    const passedFilterCheck =
                                      !columnDef.filterCheck ||
                                      (columnDef.filterCheck &&
                                        columnDef.filterCheck(value));
                                    const truncate = columnDef.truncate;
                                    return (
                                      <td
                                        key={cell.id}
                                        className={clsx(
                                          `px-3.5 py-2 border border-l-transparent border-r-transparent shadow-inner-remove w-fit relative group `,
                                          rowExpanded && "border-b-[#f7f3f356]",
                                          column.getIsPinned() &&
                                            "after:content[''] after:absolute after:left-[-1px] after:top-0 after:w-[calc(100%+2px)] after:h-full after:bg-white after:z-[-1] after:opacity-90"
                                        )}
                                        style={{
                                          ...getCommonPinningStyles(
                                            column,
                                            0,
                                            rowSelected
                                              ? "#e6e6e6"
                                              : "transparent"
                                          ),
                                          maxWidth: column.getSize(),
                                        }}
                                      >
                                        <div
                                        // style={{
                                        //   maxWidth: truncate
                                        //     ? `calc(${column.getSize()}px - 1.75rem)`
                                        //     : "100%",
                                        //   width: `100%`,
                                        // }}
                                        >
                                          {renderCell(
                                            row,
                                            cell,
                                            popupOpen,
                                            setPopupOpen,
                                            appliedFilters,
                                            saveAndApplyFilter,
                                            translate
                                          )}
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                                {rowExpanded && (
                                  <tr className="text-center">
                                    <td colSpan={columns.length}>
                                      {expandedViewRow &&
                                        expandedViewRow(
                                          row,
                                          row.context,
                                          actionFunctions
                                        )}
                                    </td>
                                  </tr>
                                )}
                                {rowSeparator &&
                                  rowSeparator(row, columns.length)}
                              </>
                            );
                          })
                        ) : (
                          <tr className="text-center h-40">
                            <td colSpan={columns.length}>
                              {translate("shared.no-records")}
                            </td>
                          </tr>
                        )}
                        {table.getBottomRows() &&
                          table
                            .getBottomRows()
                            .map((row, index) => (
                              <PinnedRow
                                key={row.id}
                                id={`row-bottom-${row.id}`}
                                row={row}
                                table={table}
                                index={index}
                                headerHeight={headerHeight}
                                pinnedRowHeight={pinnedRowHeight}
                                highlightedColor={highlightedColor}
                                submenuVisible={submenuVisible}
                                popupOpen={popupOpen}
                                setPopupOpen={setPopupOpen}
                                appliedFilters={appliedFilters}
                                saveAndApplyFilter={saveAndApplyFilter}
                              />
                            ))}
                      </tbody>
                    </table>
                    {selectableRowsKeys.length > 1 && (
                      <div
                        // bg-blue-200 backdrop-filter backdrop-blur-sm bg-opacity-10
                        className={clsx(
                          `container mx-auto absolute -top-0 -translate-y-[100%] z-[4] p-3 flex flex-row rtl:flex-row-reverse gap-2 justify-start items-center overflow-visible text-right`,
                          selectableRowsKeys.length > 0
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      >
                        <div className="flex gap-2 rtl:flex-row-reverse">
                          <button
                            className="text-blue-500 cursor-pointer flex items-center gap-1 flex-row ml-[3px]"
                            onClick={() => {
                              table.toggleAllRowsSelected(false);
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-checkbox text-blue-500 text-xs h-4 w-4 cursor-pointer"
                              // value={rowSelected}
                              checked={true}
                            />
                          </button>
                          <span className="text-sm">
                            <span className="font-semibold">
                              {selectableRowsKeys.length}
                            </span>{" "}
                            {translate(
                              selectableRowsKeys.length > 1
                                ? "shared.selected-records"
                                : "shared.selected-record"
                            )}
                          </span>
                        </div>
                        <div className="relative">
                          <button
                            disabled={selectableRowsKeys.length === 0}
                            onClick={() => toggleSubMenu("bulk")}
                            className="relative ml-0 px-2 py-1 text-blue-500 bg-white rounded  z-[5] whitespace-nowrap"
                          >
                            {translate("shared.bulk-actions")}
                          </button>
                          <div
                            className={clsx(
                              `absolute flex flex-col top-full ltr:left-0 rtl:right-0 rtl:left-[unset] justify-around w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 z-[10]`,
                              submenuVisible === "bulk" ? "visible" : "hidden"
                            )}
                          >
                            {Object.keys(GlobalActions)?.map(
                              (actionIdentifier, index) => {
                                const action = GlobalActions[actionIdentifier];
                                return (
                                  <button
                                    key={index}
                                    onClick={() => {
                                      bulkActionFunctions(
                                        action,
                                        Object.keys(selectableRows).join(",")
                                      );
                                    }}
                                    className="w-full text-start rtl:text-end hover:bg-gray-100 font-normal cursor-pointer p-2 px-4"
                                  >
                                    {action.name}
                                  </button>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {!minimalTable && (
                  <>
                    <button
                      className="absolute -right-10 top-1/2 text-2xl hover:scale-125 hover:animate-[ping_1.5s_linear_infinite] text-gray-400 hover:text-gray-700 cursor-none"
                      onMouseEnter={() => scroll("right")}
                      onClick={() => scroll("right", false)}
                      onMouseOut={() => stopScroll(scrollTimeout)}
                      tabIndex={noPopupsOpen ? -1 : 2}
                    >
                      →
                    </button>
                    <button
                      className="absolute -bottom-10 left-1/2 text-2xl hover:scale-125 hover:animate-[ping_1.5s_linear_infinite] text-gray-400 hover:text-gray-700 cursor-none"
                      onMouseEnter={() => scroll("down")}
                      onClick={() => scroll("down", false)}
                      onMouseOut={() => stopScroll(scrollTimeout)}
                      tabIndex={noPopupsOpen ? -1 : 1}
                    >
                      ↓
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* {!minimalTable && (
            <>
              <div className="h-2" />
              <div className="flex justify-between items-center flex-row mt-10">
                <div>{data.length} Rows</div>
              </div>
            </>
          )} */}
        </div>
      </KeyboardEventHandler>
    </div>
  );
};

export default CustomTable;
