import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "@/locale/en.json";
import ar from "@/locale/ar.json";

const resources = { en, ar };
const defaultLang = "en"
i18n.use(initReactI18next).use(LanguageDetector).init({
  resources: {
    en: { ...en },
    ar: { ...ar },
  }, // Where we're gonna put translations' files
//   lng: defaultLang, // Set the initial language of the App
  returnObjects: true,
  supportedLngs: ['en', 'ar'],
});
