import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "@components/CustomTable";
import ExpandedViewRow from "./expandedView";
import myStore from "./store";
import { Observer } from "mobx-react";
import {
  RunManualReconciliation,
  GetSFMDisplay,
  SkipNextReconciliationRun,
  resolveStatusColumn,
} from "@helpers/helpers";
import SfmModalStore from "@stores/SfmModalStore";
import LegendComponent from "@components/LegendComponent";
import clsx from "clsx";

const DEFAULT_MONTH_RANGE = 6;
const TRIM_CURRENT_DATE = 16;

const ReconciliationStreamsComponent = () => {
  const [parentSelectedValues, setParentSelectedValues] = useState({});
  const { t: translate, i18n } = useTranslation();
  // const statusColors = {
  //   "On Hold": "bg-gray-400",
  //   Active: "bg-green-400",
  //   Inactive: "bg-red-400",
  //   "In-progress": "bg-yellow-400",
  //   Disabled: "bg-red-400",
  // };

  const [currentDateTime, setCurrentDateTime] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - DEFAULT_MONTH_RANGE);

    const formattedDateTime = today.toISOString().slice(0, TRIM_CURRENT_DATE);
    setCurrentDateTime(formattedDateTime);
    const formatDate = (date) => {
      const pad = (num) => num.toString().padStart(2, "0");
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
    };

    setMinDate(formatDate(sixMonthsAgo));
    setMaxDate(formatDate(today));
  }, []);

  const pinningInitialState = ["pin", "ReconciliationStreamStatusName"];

  const legendData = [
  ];

  const columns = [
    {
      accessorKey: "ReconciliationStreamStatusName",
      header: translate(
        "reconciliation-streams.columns.ReconciliationStreamStatus"
      ),
      headerHelper: () => {
        return <LegendComponent data={legendData} />;
      },
      size: 50,
      sortable: true,
      // hideHeader: true,
      cell: ({ row }) => {
        return resolveStatusColumn(
          row.original.ReconciliationStreamStatusName,
          row.original.ReconciliationStreamStatusTag,
          ["INPROGRESS"],
          null,
          row.original.ReconciliationRunStatusTag
        );
      },
    },
    {
      accessorKey: "ReconciliationStreamName",
      // header: "Reconciliation Stream",
      header: translate(
        "reconciliation-streams.columns.ReconciliationStreamName"
      ),
      canFilter: true,
      hideCopy: true,
      hideFilter: true,
      isMultiple: false,
      sortable: true,
      size: 250,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.ReconciliationStreamTag;
      },
      processFilterKey: (value, row) => {
        return "ReconciliationStreamTag";
      },
    },
    {
      accessorKey: "IsScheduled",
      header: translate("reconciliation-streams.columns.Scheduled"),
      size: 99,
      canFilter: false,
      isMultiple: false,
      sortable: true,
      truncate: false,
      filterCheck: (value) => value === true,
      hideCopy: true,
      cell: ({ row }) => {
        return (
          <span
            className={clsx(
              `w-full flex justify-center`,
              !row.original.IsScheduled ? "text-red-400 " : "text-green-400"
            )}
          >
            <span
              className={`rounded-full flex items-end justify-center gap-1 w-fit font-normal text-sm px-3 py- border border-r-10 border-transparent `}
            >
              <span className="text-[15px]">
                {!row.original.IsScheduled ? "𝗫" : "✓"}
              </span>
            </span>
          </span>
        );
      },
    },
    {
      accessorKey: "PeriodicityName",
      header: translate("reconciliation-streams.columns.PeriodicityName"),
      size: 300,
      sortable: true,
      truncate: true,
      canFilter: true,
      hideCopy: true,
      hideFilter: true,
    },
    // {
    //   accessorKey: "Sequence",
    //   header: translate("reconciliation-streams.columns.Sequence"),
    //   size: 50,
    //   sortable: true,
    //   canPin: false,
    // },
    {
      accessorKey: "NextReconciliationRun",
      header: translate("reconciliation-streams.columns.NextReconciliationRun"),
      size: 200,
      canFilter: false,
      truncate: true,
      splitCellValue: "T",
    },
    {
      accessorKey: "LastSuccessfulReconciliationRun",
      header: translate(
        "reconciliation-streams.columns.LastSuccessfulReconciliationRun"
      ),
      size: 180,
      canFilter: false,
      splitCellValue: "T",
    },
    {
      accessorKey: "LastDuration",
      header: translate("reconciliation-streams.columns.LastDuration"),
      size: 160,
      canFilter: false,
    },
  ];

  const actions = {
    RUN_NOW: {
      actionTypeTag: "RUN_NOW",
      name: translate("reconciliation-streams.actions.RUN_NOW"),
      actionFN: (row) => {
        RunManualReconciliation(
          row.original.ReconciliationStreamId,
          (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData, () => {
              window.location.href = "/reconciliation-streams";
            });
          },
          myStore
        );
      },
      confirmation: true,
      confirmationMessage:
        "Do you want to initiate a manual reconciliation process? Please confirm if you wish to proceed.",
    },
    SKIP_NEXT_RUN: {
      actionTypeTag: "SKIP_NEXT_RUN",
      name: translate("reconciliation-streams.actions.SKIP_NEXT_RUN"),
      actionFN: (row) => {
        SkipNextReconciliationRun(
          row.original.ReconciliationStreamId,
          (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData, () => {
              window.location.href = "/reconciliation-streams";
            });
          },
          myStore
        );
      },
      confirmationMessage:
        "Do you want to skip the upcoming scheduled run? Please confirm if you wish to proceed.",
      confirmation: true,
    },
    // EDIT_SCHEDULE: {
    //   actionTypeTag: "EDIT_SCHEDULE",
    //   name: translate("reconciliation-streams.actions.EDIT_SCHEDULE"),
    //   actionFN: (row, ids, selectedReason, reasonDescription) => {
    //     alert(
    //       `REASON: ${selectedReason}, DESCRIPTION: ${reasonDescription}, EDIT_SCHEDULE id:${
    //         row?.id || ids
    //       }`
    //     );
    //   },
    //   confirmation: true,
    // },
  };

  return (
    <Observer>
      {() => {
        return (
          <>
            <CustomTable
              store={myStore}
              columnsWithoutPin={columns}
              loading={myStore.loading}
              filterDisplayValues={{}}
              selectedFiltersDisplay={{}}
              filters={{}}
              pinningInitialState={pinningInitialState}
              setParentSelectedValues={setParentSelectedValues}
              actions={actions}
              prefix="ReconciliationStreams"
              title={translate("reconciliation-streams.title")}
              pinCondition={(row) => {
                return false;
              }}
              expandedViewRow={(row, context, actionFunctions) => {
                return (
                  <ExpandedViewRow
                    row={row}
                    context={context}
                    actionFunctions={actionFunctions}
                  />
                );
              }}
              pinnedRowHeight={57}
              showFilter={false}
              language={i18n.language}
              hideActionOnRow={(row) => {
                const statusTag = row.original.ReconciliationStreamStatusTag;
                return statusTag === "DISABLED";
              }}
            />
            {/* {!myStore.loading && (
              <div className="container mx-auto">
                <div className="mx-auto w-[90%] pt-5">
                  <LegendComponent data={legendData} inline />
                </div>
              </div>
            )} */}
          </>
        );
      }}
    </Observer>
  );
};

export default ReconciliationStreamsComponent;
