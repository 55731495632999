import React, { useState } from "react";
import clsx from "clsx";

const TailwindDropdown = ({ data, setActiveTab, selectedValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = (key) => {
    setActiveTab(key);
    setIsOpen(false);
  };
  return (
    <div className="relative inline-block text-left mt-4">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          {data.find((item) => item.key === selectedValue)?.value || "Select"}
          <svg
            className="-mr-1 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden={isOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div
        className={`absolute left-0 z-10 w-full origin-top-right top-full rounded-md bg-white focus:outline-none ${
          isOpen ? "border-[1px] border-t-0" : " ring-0 border-0"
        }`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        <div
          className={clsx(
            `grid grid-rows-[0fr] transition-all ease-in-out duration-500`,
            isOpen ? "grid-rows-[1fr]" : ""
          )}
        >
          <div className="overflow-hidden">
            {data.map((item, index) => (
              <div
                key={index}
                className="py-1 hover:bg-gray-100 rounded"
                role="button"
                onClick={() => handleOptionSelect(item.key)}
              >
                <div
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  id="menu-item-0"
                >
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailwindDropdown;
