import ListingStore from "@stores/ListingStore";
import { GetSFMDisplay } from "@helpers/helpers";
import { apiHelper } from "@helpers/apiHelper";
import SfmModalStore from "@stores/SfmModalStore";
const STORE_LIMIT = 50;
class Store extends ListingStore {
  microserviceInput = "";
  dataKey = "DataAcquisitions";
  limit = STORE_LIMIT;
  offset = 0;
  setMicroservice(microservice) {
    this.microserviceInput = microservice;
  }

  async getData() {
    const externalurl = `/api/v1/DataAcquisition/GetDataAcquisitions`;
    const activityTag = "GetDataAcquisitions";

    const apiRequest = apiHelper({
      externalurl,
      activityTag,
      filter: {
        enabled: true,
        value: this.filter,
      },
      sort: {
        enabled: true,
        value: this.sort,
      },
      limit: this.limit,
      offset: this.offset,
    });

    return apiRequest
      .then((data) => {
        const sfmData = GetSFMDisplay(data.SFM);
        SfmModalStore.open(sfmData);
        return {
          data: data.DataAcquisitions,
          total: data.DataAcquisitions.length, // You might need to adjust this depending on the actual response structure
          page: 1, // Adjust if pagination data is available in the response
        };
      })

      .catch((error) => {
        return;
      });
  }
}

const myStore = new Store();

export default myStore;
