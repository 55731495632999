import React from "react";
import { getCommonPinningStyles } from "./helpers";
import renderCell from "./renderCell";
import clsx from "clsx";

const PinnedRow = ({
  row,
  table,
  index,
  id,
  headerHeight,
  pinnedRowHeight,
  highlightedColor,
  submenuVisible,
  popupOpen,
  setPopupOpen,
  appliedFilters,
  saveAndApplyFilter,
}) => {
  const rowHeight = pinnedRowHeight;
  const additionalSpace = headerHeight;

  return (
    <tr
      id={`pinned-row-${row.id}`}
      style={{
        backgroundColor: highlightedColor,
        width: "100%",
        zIndex: submenuVisible === row.id ? 5 + row.id : 2,
        position: "sticky",
        boxShadow: "0px 0px 1px #e1e1e1",
        top:
          row.getIsPinned() === "top"
            ? `${row.getPinnedIndex() * rowHeight + additionalSpace}px`
            : undefined,
        bottom:
          row.getIsPinned() === "bottom"
            ? `${
                (table.getBottomRows().length - 1 - row.getPinnedIndex()) *
                rowHeight
              }px`
            : undefined,
      }}
    >
      {row.getVisibleCells().map((cell) => {
        const { column } = cell;
        return (
          <td
            key={cell.id}
            className={clsx(
              `px-3.5 py-2 border border-l-transparent border-r-transparent shadow-inner-remove w-fit group`,
              column.getIsPinned() &&
                "after:content[''] after:absolute after:left-[-1px] after:top-0 after:w-[calc(100%+2px)] after:h-full after:bg-white after:z-[-1] after:opacity-70"
            )}
            style={{
              ...getCommonPinningStyles(
                column,
                index,
                highlightedColor,
                null,
                1,
                1
              ),
              width: column.getSize(),
            }}
          >
            <div
              // style={{
              //   width: `calc(${column.getSize()}px - 1.75rem)`,
              // }}
            >
              {renderCell(
                row,
                cell,
                popupOpen,
                setPopupOpen,
                appliedFilters,
                saveAndApplyFilter
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default PinnedRow;
