import React from "react";

const TitleComponent = ({ text, description, size = 'md' }) => {
  return (
    <div className="flex flex-col gap-1 rtl:text-right">
      {text && <p className={`font-semibold text-gray-900 text-${size}`}>{text}</p>}
      {description && <p className="text-sm text-gray-500">{description}</p>}
    </div>
  );
};

export default TitleComponent;
