import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const SideBarItem = ({
  index,
  Menu,
  activeIndex,
  setActiveIndex,
  open,
  setOpenSubMenus,
  openSubMenus,
}) => {
  const { t: translate } = useTranslation();
  const handleSubMenuToggle = (index) => {
    setOpenSubMenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const navigate = useNavigate();

  return (
    <li
      key={index}
      className={clsx(
        `flex flex-col cursor-pointer text-white text-sm gap-y-2 has-tooltip`,
        Menu.gap ? "mt-9" : "mt-2"
      )}
      onClick={() => setActiveIndex(index)}
      title={translate(`menu.${Menu.title}`)} // Add title attribute here
    >
      <div
        className={clsx(
          `flex items-center gap-x-4 hover:bg-sky-300 hover:font-bold rounded-md p-2 ltr:pr-0 rtl:pl-0 justify-between`,
          index === activeIndex && "bg-sky-400"
        )}
        onClick={() => {
          setActiveIndex(index);
          Menu.subItems && handleSubMenuToggle(index);
          navigate(Menu.path);
        }}
      >
        <div
          className={clsx(
            `flex gap-x-4`,
            !open ? "flex-col items-start" : "items-center"
          )}
        >
          {Menu.svgIcon}
          {!Menu.svgIcon && (
            <img src={require(`@assets/${Menu.icon}.png`)} alt={Menu.title} />
          )}
          {(open || Menu.tinyTitle) && (
            <span
              className={clsx(
                `origin-left duration-200`,
                !open && "text-xs min-w-20"
              )}
            >
              {open ? translate(`menu.${Menu.title}`) : Menu.tinyTitle}
            </span>
          )}
        </div>
        {Menu.subItems && open && (
          <svg
            className={clsx(
              `w-4 h-4 transition-transform duration-300`,
              openSubMenus[index] ? "rotate-90" : "rotate-0"
            )}
            fill="none"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        )}
      </div>
      {Menu.subItems && (
        <ul
          className={clsx(
            `pl-8 overflow-hidden transition-all duration-200 ease-in-out`,
            openSubMenus[index] ? "max-h-dvh" : "max-h-0"
          )}
        >
          {Menu.subItems.map((subItem, subIndex) => (
            <li
              key={`sidebarItem_${subIndex}`}
              className={clsx(
                `flex items-center hover:bg-sky-400 gap-y-2 mb-5 gap-x-4 mt-2 rounded-md p-2`,
                "subIndex_" + subIndex === activeIndex && "bg-sky-400"
              )}
              onClick={(e) => {
                e.stopPropagation();
                setActiveIndex(`subIndex_${subIndex}`);
              }}
            >
              {subItem.svgIcon}
              {subItem.icon && !subItem.svgIcon ? (
                <img
                  src={require(`@assets/${subItem.icon}.png`)}
                  alt={subItem.title}
                />
              ) : null}
              <span>{subItem.title}</span>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default SideBarItem;
