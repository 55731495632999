import React, {forwardRef} from "react";

const RadioComponent = forwardRef(({ filter, handleChange, value, index }) => {
  return (
    <div className="flex flex-col rtl:text-right">
      <span className="mb-2 font-semibold text-sm text-gray-500">{filter.name}</span>
      {filter.values.map((option) => (
        <label key={option.value} className="inline-flex items-center mb-2">
          <input
            type="radio"
            name="direction"
            value={option.value}
            onChange={handleChange}
            {...((value[filter.key] || []).includes(option.value) && {
              checked: true,
            })}
            className="form-radio text-blue-600"
            tabIndex={index}
          />
          <span className="ltr:ml-2 rtl:mr-2">{option.displayName}</span>
        </label>
      ))}
    </div>
  );
});

RadioComponent.displayName = "RadioComponent";

export default RadioComponent;
