const displayValues = {
  DataAcquisitionRevocations: "Data Revocations",
  StartDate: "Start Date",
  EndDate: "End Date",
  StatusTag: "Status Tag",
  Status: "Status",
  RecordsRange: "Records Range",
  TotalRevokedRecords: "Total Revoked Records",
  TotalPromotedRecords: "Total Promoted Records",
  RevokedDataFMSId: "Revoked Data FMS ID",
  RevokedDataFileName: "Revoked Data File Name",
  RevokedDataFileSizeInMb: "Revoked Data File Size In MB",
  DataRevocationFeedback: "Data Revocation Feedback",
  DataRevocationFeedbackDescription: "Data Revocation Feedback Description",
  DataAcquisitionInstanceId: "Data Acquisition Instance ID",
  DataAcquisitionId: "Data Acquisition ID",
  DataAcquisitionTag: "Data Acquisition Tag",
  DataAcquisitionName: "Data Acquisition Name",
  DataAcquisitionDescription: "Data Acquisition Description",
  TenantId: "Tenant ID",
  TenantCode: "Tenant Code",
  TenantName: "Tenant Name",
  PeriodicityTag: "Periodicity Tag",
  PeriodicityName: "Periodicity Name",
  IsFileDrop: "Is File Drop",
  IsManualTrigger: "Is Manual Trigger",
  IsScheduleTrigger: "Is Schedule Trigger",
  IsRetry: "Is Retry",
  RetryCount: "Retry Count",
  ScheduleDate: "Schedule Date",
  Feedback: "Feedback",
  FeedbackDescription: "Feedback Description",
  RawDataFMSId: "Raw Data FMS ID",
  RawDataFileName: "Raw Data File Name",
  RawDataFileSizeInMb: "Raw Data File Size In MB",
  DataFetchingFeedback: "Data Fetching Feedback",
  DataFetchingFeedbackDescription: "Data Fetching Feedback Description",
  TotalRawRecords: "Total Raw Records",
  TotalFaultyRecords: "Total Faulty Records",
  FaultyDataFMSId: "Faulty Data FMS ID",
  FaultyDataFileName: "Faulty Data File Name",
  FaultyDataFileSizeInMb: "Faulty Data File Size In MB",
  DataPromotionFeedback: "Data Promotion Feedback",
  DataPromotionFeedbackDescription: "Data Promotion Feedback Description",
  DataAcquisitionFetching: "Data Fetched",
  DataAcquisitionPromotion: "Data Promoted",
  basicInfo: "Basic",
  ReconciliationRunId: "Reconciliation Run ID",
  ReconciliationStreamId: "Reconciliation Stream ID",
  ReconciliationStreamName: "Reconciliation Stream Name",
  ReconciliationStreamDescription: "Reconciliation Stream Description",
  BusinessDay: "Business Day",
  BDInstance: "BD Instance",
  SourceRecordsFetching: "Source Records Fetching",
  ReconciliationStreamSideTag: "Reconciliation Stream Side Tag",
  ReconciliationStreamSideName: "Reconciliation Stream Side Name",
  ReconciliationStreamSideDescription: "Reconciliation Stream Side Description",
  SourceRecordsFetchingFeedback: "Source Records Fetching Feedback",
  SourceRecordsFetchingFeedbackDescription: "Source Records Fetching Feedback Description",
  TotalDestinationRecords: "Total Destination Records",
  DestinationRecordsFetchingFeedback: "Destination Records Fetching Feedback",
  DestinationRecordsFetchingFeedbackDescription: "Destination Records Fetching Feedback Description",
  TotalExceptionRecords: "Total Exception Records",
  TotalMatchingRecords: "Total Matching Records",
  RecordsMatchingFeedbackDescription: "Records Matching Feedback Description",
  MatchingResultsFMSId: "Matching Results FMS ID",
  MatchingResultsFileName: "Matching Results File Name",
  MatchingResultsFileSizeInMb: "Matching Results File Size In MB",
  RecordsFetching: "Records Fetching",
  RecordsMatching: "Records Matching",
  ReconciliationRollback: "Reconciliation Rollback",
  TotalRolledBackMatchingRecords: "Total Rolled Back Matching Records",
  RollbackResultsFileName: "Rollback Results File Name",
  RollbackResultsFileSizeInMb: "Rollback Results File Size In MB",
  RollbackReportingFeedbackDescription: "Rollback Reporting Feedback Description",
  TotalReconciledRecords: "Total Reconciled Records",
  TotalInTransitRecords: "Total In-Transit Records",
  RecordsMatchingFeedback: "Records Matching Feedback",
  DestinationRecordsFetching: "Destination Records Fetching",
};

export default displayValues;
