import React, { useEffect, useState, useRef } from "react";

import InputComponent from "./components/InputComponent";
import CheckboxGroup from "./components/CheckboxComponent";
import RadioComponent from "./components/RadioComponent";
import SwitchComponent from "./components/SwitchComponent";
import Dropdown from "./components/DropDownComponent";
import FilterTitleComponent from "./components/FilterTitleComponent";

import ButtonComponent from "@components/ButtonComponent";
import clsx from "clsx";

import KeyboardEventHandler from "react-keyboard-event-handler";

import { useTranslation } from "react-i18next";
import { helperTitle } from "@helpers/helpers";
import { toast } from "react-toastify";

const FOCUS_TIMEOUT_VALUE = 300;

const FiltersComponent = (props) => {
  const {
    updateAppliedFilters,
    appliedFilters: parentAppliedFilters,
    openFilter,
    setOpenFilter,
    filterDisplayValues,
    filters,
    selectedFiltersDisplay,
    setSelectedValues,
    selectedValues,
    multipleValueColumns,
  } = props;

  const { t: translate, i18n } = useTranslation();

  useEffect(() => {
    if (
      parentAppliedFilters &&
      JSON.stringify(parentAppliedFilters) !== JSON.stringify(appliedFilters)
    ) {
      setSelectedValues(parentAppliedFilters);
      setAppliedfilters(parentAppliedFilters);
    }
  }, [parentAppliedFilters]);

  useEffect(() => {
    if (openFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [openFilter]);

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [clearFilterTriggered, setClearFilterTriggered] = useState(false);
  const [appliedFilters, setAppliedfilters] = useState({});

  const mainButtonRef = useRef(null);
  const firstFocusableItem = useRef(null);
  useEffect(() => {
    mainButtonRef.current.focus();
  }, []);

  const getValuesArray = (key) => {
    return filterDisplayValues[key];
  };

  const handleSwitchChange = (e, filter) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [filter.key]: {
          value: true,
          filterType: filter.filterType,
          operator: filter.operator,
          displayName: filter.value,
        },
      }));
    } else {
      const { [filter.key]: removedValue, ...rest } = selectedValues;
      setSelectedValues(rest);
    }
  };

  const handleChange = (
    e,
    filter,
    isNumber = false,
    decimalPoint,
    isRange = false,
    valueToShow
  ) => {
    const { name, value } = e.target;
    if (value === "") {
      const { [name]: removedValue, ...rest } = selectedValues;
      setSelectedValues(rest);
    } else {
      let calculatedValue = isNumber
        ? !!decimalPoint
          ? parseFloat(value).toFixed(decimalPoint)
          : parseFloat(value).toString()
        : value;
      if (isRange) {
        if (valueToShow) {
          calculatedValue = valueToShow.split("|")[0] + "|" + value;
        } else {
          calculatedValue = value + "|";
        }
      }
      if (calculatedValue < 0) {
        return toast.error(translate("shared.negative-value-error"));
      } else {
        setSelectedValues((prevValues) => ({
          ...prevValues,
          [name]: {
            value: calculatedValue,
            filterType: filter.filterType,
            operator: filter.operator,
            displayName:
              filter.filterType === "dropdown"
                ? filter?.values.find((f) => f.value === calculatedValue)
                    ?.displayName
                : calculatedValue,
          },
        }));
      }
    }
  };

  const handleSelectAll = (children, group) => {
    setSelectedValues((prev) => {
      let newState = { ...prev };
      const existingValues = newState[group.key]
        ? newState[group.key].value.split(",")
        : [];
      const existingDisplay = newState[group.key]
        ? newState[group.key].displayName.split(",")
        : [];

      children.forEach((childInner) => {
        if (!existingValues.includes(childInner.value)) {
          existingValues.push(childInner.value);
          existingDisplay.push(childInner.displayName);
        }
      });

      newState[group.key] = {
        value: existingValues.join(","),
        filterType: group.filterType,
        operator: group.operator,
        displayName: existingDisplay.join(","),
      };

      return newState;
    });
  };
  const handleDeselectAll = (children, group) => {
    setSelectedValues((prev) => {
      let newState = { ...prev };

      delete newState[group.key];

      return newState;
    });
  };

  const handleCheckBoxChange = (e, singleGroup, isChild = false, group) => {
    const { checked } = e.target;
    setSelectedValues((prev) => {
      let newState = { ...prev };

      if (checked) {
        if (!isChild) {
          // If it's a parent, add all its children to the array
          if (singleGroup.children) {
            const childValues = singleGroup.children.map(
              (child) => child.value
            );
            const childDisplay = singleGroup.children.map(
              (child) => child.displayName
            );

            const valuesJoined = childValues
              .map((childInner) => childInner)
              .join(",");
            const displayNamesJoined = childDisplay
              .map((childInner) => childInner)
              .join(",");

            newState[group.key] = {
              value: valuesJoined,
              filterType: group.filterType,
              operator: group.operator,
              displayName: displayNamesJoined,
            };
          } else {
            const singleValue = {
              value: singleGroup.value,
              filterType: group.filterType,
              operator: group.operator,
            };
            newState[group.key] = prev[group.key]
              ? [...new Set([...prev[group.key], singleValue])]
              : [singleValue];
          }
        } else if (isChild) {
          const parent = group.values.find((p) =>
            p.children
              ? p.children.some((child) => child.value === singleGroup.value)
              : p.value === singleGroup.value
          );
          if (parent) {
            const existing = newState[group.key]
              ? newState[group.key].value
              : "";
            const existingDisplay = newState[group.key]
              ? newState[group.key].displayName
              : "";
            newState[group.key] = {
              value: existing
                ? `${existing},${singleGroup.value}`
                : singleGroup.value,
              filterType: group.filterType,
              operator: group.operator,
              displayName: existingDisplay
                ? `${existingDisplay},${singleGroup.displayName}`
                : singleGroup.displayName,
            };
          }
        }
      } else {
        if (!isChild) {
          if (singleGroup.children) {
            // If it's a parent with children, remove all its children from the state
            newState[group.key] = {
              value: singleGroup.children.forEach((child) => {
                newState[group.key].value
                  .split(",")
                  .filter((value) => value !== child.value);
              }),
              filterType: group.filterType,
              operator: group.operator,
              displayName: singleGroup.children.forEach((child) => {
                newState[group.key].displayName
                  .split(",")
                  .filter((displayName) => displayName !== child.displayName);
              }),
            };
          }
          if (
            newState[group.key].value === "" ||
            newState[group.key].value === undefined
          ) {
            delete newState[group.key];
          }
        } else if (isChild) {
          // If it's a child, remove it from the parent's key
          newState[group.key] = {
            value: newState[group.key].value
              .split(",")
              .filter((status) => status !== singleGroup.value)
              .join(","),
            filterType: group.filterType,
            operator: group.operator,
            displayName: newState[group.key].displayName
              .split(",")
              .filter((status) => status !== singleGroup.displayName)
              .join(","),
          };
          // If all children are unchecked, remove the parent key if it exists
          const parent = group.values.find((p) =>
            p.children
              ? p.children.some((child) => child.value === singleGroup.value)
              : p.value === singleGroup.value
          );
          if (parent) {
            if (newState[group.key].value === "") {
              delete newState[group.key];
            }
          }
        }
      }

      // If the array under the key is empty, remove the key from the newState
      if (newState[group.key] && newState[group.key].length === 0) {
        delete newState[group.key];
      }

      return newState;
    });
  };
  const findValues = (key, valuesToFind, displayValue) => {
    let foundValues = [];
    let values = [];
    values = getValuesArray(key);

    values.forEach((method) => {
      if (method.children) {
        method.children.forEach((child) => {
          if (valuesToFind.includes(child.value)) {
            foundValues.push(child.displayName);
          }
        });
      } else {
        if (valuesToFind.includes(method.value)) {
          foundValues.push(method.displayName);
        }
      }
    });
    foundValues.length === 0 && foundValues.push(displayValue);
    return foundValues.join(", ");
  };

  const clearSingleFilter = (key, value) => {
    const newFilters = {
      ...selectedValues,
    };
    delete newFilters[key];
    setSelectedValues(newFilters);
    setAppliedfilters(newFilters);
    updateAppliedFilters(newFilters);
    setClearFilterTriggered(true);
  };

  const handleResetFilters = () => {
    setSelectedValues({});
    setAppliedfilters({});
    updateAppliedFilters({});
  };

  useEffect(() => {
    if (clearFilterTriggered) {
      setAppliedfilters(selectedValues);
      setClearFilterTriggered(false);
    }
  }, [clearFilterTriggered, selectedValues]);

  useEffect(() => {
    if (openFilter) {
      setTimeout(() => {
        // mainButtonRef.current.focus();
        firstFocusableItem.current.focus();
      }, FOCUS_TIMEOUT_VALUE);
    } else {
      mainButtonRef.current.focus();
    }
  }, [openFilter]);

  const renderActiveFilters = (selectedValues) => {
    return (
      <div className="relative z-[8] flex flex-wrap gap-3 items-center">
        {Object.entries(selectedValues).map(([key, singleFilter], index) => {
          let displayKey = selectedFiltersDisplay[key] || key;

          let switchDisplayOrder = false;

          let displayValue = singleFilter.displayName;
          if (typeof value === "boolean") {
            displayValue = singleFilter.value ? "" : "Not";
            displayKey = selectedFiltersDisplay[key];
            switchDisplayOrder = true;
          }
          let foundValuesString = multipleValueColumns.includes(key)
            ? findValues(key, singleFilter, displayValue)
            : displayValue?.split(",")?.join(", ");
          if (
            singleFilter.filterType === "date" &&
            singleFilter.value.split("|").length > 1
          ) {
            foundValuesString = (
              <div className="flex items-center gap-1">
                {singleFilter.value.split("|")[0]}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
                {singleFilter.value.split("|")[1]}
              </div>
            );
          }
          return (
            <div
              key={index}
              className="flex items-center gap-1 single-selected-filter bg-[#edeffd] p-1 rounded-lg px-4 group"
              onKeyDown={(e) => {
                e.key === "x" && clearSingleFilter(key, singleFilter);
              }}
              tabIndex={index + 10}
            >
              <div
                className={clsx(
                  `flex gap-1`,
                  switchDisplayOrder ? "flex-row-reverse" : "flex-row"
                )}
              >
                <strong className="max-w-max w-full">{displayKey}</strong>
                {foundValuesString}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-5 max-w-max w-full text-gray-400 cursor-pointer group-hover:text-red-500 hover:scale-125 hover:animate-pulse transition-all duration-700 ease-in-out"
                onClick={() => clearSingleFilter(key, singleFilter)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          );
        })}
        {Object.keys(appliedFilters).length >= 2 && (
          <ButtonComponent
            withoutBorder={true}
            id="options-menu"
            color="blue"
            onClick={handleResetFilters}
            ref={null}
            icon={null}
            text={translate("shared.reset-filters")}
            minWidth={false}
          />
        )}
      </div>
    );
  };

  const renderFilterValues = (filter, index, isTopFilter = false) => {
    return (
      <div key={filter.key} className="flex flex-col gap-2" tabIndex={1}>
        {filter.components.map((singleComponent) => {
          if (singleComponent.type === "radioGroup") {
            return (
              <RadioComponent
                key={singleComponent.key}
                filter={singleComponent}
                handleChange={handleChange}
                value={selectedValues}
                index={index}
                ref={isTopFilter ? firstFocusableItem : null}
              />
            );
          } else if (
            singleComponent.type === "numberInput" ||
            singleComponent.type === "numberMin" ||
            singleComponent.type === "numberMax" ||
            singleComponent.type === "dateInput" ||
            singleComponent.type === "searchInput"
          ) {
            return (
              <InputComponent
                key={singleComponent.key}
                filter={singleComponent}
                handleChange={handleChange}
                value={selectedValues[singleComponent.key]}
                isNumber={singleComponent.type === "numberInput" ? true : false}
                isText={singleComponent.type === "searchInput" ? true : false}
                index={index}
                ref={isTopFilter ? firstFocusableItem : null}
              />
            );
          } else if (singleComponent.type === "checkboxGroup") {
            return (
              <CheckboxGroup
                key={index}
                group={singleComponent}
                handleChange={handleCheckBoxChange}
                value={selectedValues}
                handleSelectAll={handleSelectAll}
                handleDeselectAll={handleDeselectAll}
                index={index}
                ref={isTopFilter ? firstFocusableItem : null}
              />
            );
          } else if (singleComponent.type === "switch") {
            return (
              <SwitchComponent
                key={singleComponent.key}
                filter={singleComponent}
                handleChange={handleSwitchChange}
                value={selectedValues}
                index={index}
                ref={isTopFilter ? firstFocusableItem : null}
              />
            );
          } else if (singleComponent.type === "dropDown") {
            return (
              <Dropdown
                key={index}
                filter={singleComponent}
                handleChange={handleChange}
                selectedOption={selectedValues[singleComponent.key]}
                index={index}
                ref={isTopFilter ? firstFocusableItem : null}
              />
            );
          } else if (singleComponent.type === "title") {
            return (
              <FilterTitleComponent key={index} filter={singleComponent} />
            );
          }
          return null; // Explicitly return null for components that don't match the condition
        })}
      </div>
    );
  };

  const handleClickOutside = () => {
    if (JSON.stringify(selectedValues) !== JSON.stringify(appliedFilters)) {
      setOpenPopup(true);
    } else {
      setTimeout(() => {
        mainButtonRef.current.focus();
      }, FOCUS_TIMEOUT_VALUE);
    }
    setOpenFilter(false);
  };

  const handleApplyFilters = () => {
    setOpenPopup(false);
    setOpenFilter(false);
    setAppliedfilters(selectedValues);
    updateAppliedFilters(selectedValues);
  };

  const handleApplyFiltersOld = () => {
    setSelectedValues(appliedFilters);
    setOpenPopup(false);
  };

  const handleButtonClick = () => {
    let TOP_OFFSET = 0;
    let LEFT_OFFSET = 0;
    setTimeout(() => {
      const parent = document.getElementById(`parent_filter`);
      const child = document.getElementById(`child`);

      if (child) {
        const parentRect = parent.getBoundingClientRect();

        const top = parentRect.top;
        const left = parentRect.left;
        if (i18n.language === "ar") {
          child.style.left = `unset`;
        } else {
          child.style.left = `${left + LEFT_OFFSET}px`;
        }
        child.style.top = `${top + TOP_OFFSET}px`;
      }
    }, 0);
    openFilter ? handleClickOutside() : setOpenFilter(true);
  };

  const onKeyEvent = (key) => {
    if (key === "esc") {
      handleClickOutside(true);
    }
    if (key === "y") {
      handleApplyFilters();
    }
    if (key === "n") {
      handleApplyFiltersOld();
    }
    if (key === "r") {
      Object.keys(appliedFilters).length >= 2 && handleResetFilters();
    }
  };
  const topFiltersLength = filters[0]?.topFilters?.length;
  const mainFiltersLength = filters[0].mainFilters.length;
  const additionalFiltersLength = filters[0]?.additionalFilters?.length;

  const filterdFoundInMainFilters = filters[0].mainFilters.find(
    (filter) => filter.key === selectedFilter
  );

  return (
    <KeyboardEventHandler
      handleKeys={["esc", "y", "n", "r"]}
      onKeyEvent={(key) => onKeyEvent(key)}
    >
      {openFilter && (
        <div
          className="fixed top-0 left-0 w-full h-dvh bg-black opacity-25 z-[8]"
          onClick={handleClickOutside}
        />
      )}
      <div
        id="parent_filter"
        className={clsx(
          `relative inline-flex gap-5 text-left text-black flex-col items-start`,
          openFilter ? "z-[9]" : "z-[2]"
        )}
      >
        <div>
          <ButtonComponent
            isactive={openFilter}
            color="blue"
            id="options-menu"
            onClick={() => handleButtonClick()}
            ref={mainButtonRef}
            minWidth={false}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
                />
              </svg>
            }
            text={
              <span className="font-semibold">
                {helperTitle(translate("shared.columns-filters"))}
                {appliedFilters && Object.keys(appliedFilters).length > 0 && (
                  <span className="text-red-400 text-xs font-normal ml-1">
                    ({Object.keys(appliedFilters).length})
                  </span>
                )}
              </span>
            }
          />
        </div>
        {renderActiveFilters(selectedValues)}
        {openFilter && (
          <div
            id="child"
            className=" z-[9] fixed mt-10 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
          >
            <div className=" flex flex-row divide-gray-100 min-h-[460px]">
              <div className="flex flex-col justify-between p-2 bg-gray-50 w-[260px]">
                <div>
                  {!!filters[0]?.topFilters && (
                    <div className="border-b-gray-100 border-b-2 mb-4">
                      {filters[0]?.topFilters?.map((filter, index) =>
                        renderFilterValues(filter, index, true)
                      )}
                    </div>
                  )}
                  {filters[0].mainFilters.map((filter, index) => (
                    <button
                      key={filter.key}
                      className={clsx(
                        `flex items-center gap-2 cursor-pointer mb-1 px-2 py-2 w-auto hover:bg-gray-100 hover:rounded-xl`,
                        selectedFilter === filter.key &&
                          "bg-gray-200 rounded-xl"
                      )}
                      onClick={() => setSelectedFilter(filter.key)}
                      ref={
                        !filters[0]?.topFilters
                          ? index === 0
                            ? firstFocusableItem
                            : null
                          : null
                      }
                      tabIndex={topFiltersLength + index + 1}
                    >
                      {filter.icon && (
                        <div className="w-4 h-4">{filter.icon()}</div>
                      )}
                      {filter.displayName}
                    </button>
                  ))}
                  {showAdditionalFilters &&
                    filters[0].additionalFilters.map((filter, index) => (
                      <button
                        key={filter.key}
                        className={clsx(
                          `flex items-center gap-2 cursor-pointer px-2 py-2 w-auto hover:bg-gray-100 hover:rounded-xl`,
                          selectedFilter === filter.key &&
                            "bg-gray-200 rounded-xl"
                        )}
                        onClick={() => setSelectedFilter(filter.key)}
                        tabIndex={
                          topFiltersLength +
                          mainFiltersLength +
                          additionalFiltersLength +
                          index +
                          2
                        }
                      >
                        {filter.icon && (
                          <div className="w-4 h-4">{filter.icon()}</div>
                        )}
                        {filter.displayName}
                      </button>
                    ))}
                  {additionalFiltersLength && (
                    <button
                      onClick={() => {
                        setShowAdditionalFilters(!showAdditionalFilters);
                      }}
                      className="text-xs px-2 py-2 hover:underline text-gray-400"
                      tabIndex={
                        showAdditionalFilters
                          ? topFiltersLength +
                            mainFiltersLength +
                            additionalFiltersLength +
                            1
                          : topFiltersLength + mainFiltersLength + 1
                      }
                    >
                      {showAdditionalFilters
                        ? translate("shared.hide-additional-filters")
                        : translate("shared.show-additional-filters")}
                    </button>
                  )}
                </div>
                <ButtonComponent
                  type="apply"
                  id="options-menu"
                  onClick={handleApplyFilters}
                  ref={null}
                  icon={null}
                  text={translate("shared.apply-filters")}
                  {...(JSON.stringify(selectedValues) ===
                    JSON.stringify(appliedFilters) && {
                    disabled: true,
                  })}
                  tabIndex={
                    topFiltersLength +
                    mainFiltersLength +
                    additionalFiltersLength +
                    5
                  }
                />
              </div>
              <div className="p-4 pr-10 pl-8 min-w-[400px] rtl:text-right">
                {selectedFilter ? (
                  <div>
                    {renderFilterValues(
                      filterdFoundInMainFilters ||
                        filters[0].additionalFilters.find(
                          (filter) => filter.key === selectedFilter
                        ),
                      filterdFoundInMainFilters
                        ? topFiltersLength +
                            1 +
                            filters[0].mainFilters.findIndex(
                              (filter) => filter.key === selectedFilter
                            )
                        : topFiltersLength +
                            mainFiltersLength +
                            additionalFiltersLength +
                            2 +
                            filters[0].additionalFilters.findIndex(
                              (filter) => filter.key === selectedFilter
                            )
                    )}
                  </div>
                ) : (
                  <div>{translate("shared.select-filter-message")}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {openPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[100]">
          <div className="bg-white rounded-lg shadow-lg p-6 relative min-w-[400px]">
            <p className="text-center">
              {translate("shared.filters-confirmation-message")}
            </p>
            <div className="flex text-center w-full justify-around items-center mt-6">
              <ButtonComponent
                color="green"
                id="options-menu"
                onClick={handleApplyFilters}
                ref={null}
                icon={null}
                text={translate("shared.yes")}
              />
              <ButtonComponent
                color="red"
                id="options-menu"
                onClick={handleApplyFiltersOld}
                ref={null}
                icon={null}
                text={translate("shared.no")}
              />
            </div>
          </div>
        </div>
      )}
    </KeyboardEventHandler>
  );
};

export default FiltersComponent;
