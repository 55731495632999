import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoModalStore from "@stores/InfoModalStore";

import Sidebar from "@components/SideBar";
import "./App.css";
import ReconciliationRunsComponent from "@components/ReconciliationRunComponent";
import ReconciliationStreamsComponent from "@components/ReconciliationStreamsComponent";
import DataAcquisitions from "@components/DataAcquisitionsComponent";
import DataAcquisitionsInstances from "@components/DataAcquisitionsInstancesComponent";
import InTransitListComponent from "@components/InTransitListComponent";
import ExceptionsRecordComponent from "@components/ExceptionsRecordComponent";
import Welcome from "@components/Welcome";
import SfmModal from "@modals/SfmModal";
import InfoModal from "@modals/InfoModal";
import DownloadModal from "@modals/DownloadModal";
import AttemptDetails from "@components/AttemptDetails";
import RunMatchingRecords from "@components/RunMatchingRecords";
import AttemptMatching from "@components/AttemptMatching";
import GlobalStore from "@stores/GlobalStore";
import ReconciliationSummary from "@components/ReconciliationSummaryComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Menus from "./Menus";

const usePageChange = (callback) => {
  const location = useLocation();

  useEffect(() => {
    callback(location);
  }, [location, callback]);
};

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    GlobalStore.setGlobalSettings({
      languageCode: i18n.language,
    });
  });

  // useEffect(() => {
  //   GlobalStore.setGlobalSettings({
  //     timeZone: "Asia/Riyadh",
  //     ttpTenantCode: "3333",
  //     ttpUserId: "2222_USER_1",
  //     languageCode: i18n.language,
  //   });
  // }, []);

  // Callback function to handle page changes
  const handlePageChange = (location) => {
    InfoModalStore.showing = false; // Reset InfoModal state
  };

  // Use the custom hook to detect page changes
  return (
    <BrowserRouter>
      <PageChangeHandler onPageChange={handlePageChange} />
      <div className="flex flex-col relative overflow-x-hidden">
        <Sidebar menus={Menus} />
        <div className="pl-[100px] rtl:pl-0 rtl:pr-[100px] py-5">
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route
              path="/reconciliation-runs"
              element={<ReconciliationRunsComponent />}
            />
            <Route
              path="/reconciliation-streams"
              element={<ReconciliationStreamsComponent />}
            />
            <Route path="/data-acquisitions" element={<DataAcquisitions />} />
            <Route
              path="/data-acquisitions-instances"
              element={<DataAcquisitionsInstances />}
            />
            <Route
              path="/in-transit-summary"
              element={
                <ReconciliationSummary
                  ReconciliationSummaryTitle="In-Transit Summary"
                  ReconciliationSummaryTag="INTRANSIT"
                  redirectionURL="/in-transit-list"
                  headers={[
                    {
                      label: "Total Records",
                      mapping: "INTRANSIT_RECORDS",
                      amountClassName:
                        "text-14 bg-gray-100 p-[5px] rounded-[5px]",
                    },
                    {
                      label: "Critical Records",
                      mapping: "INTRANSIT_CRITICAL",
                      className: "text-red-400",
                      amountClassName:
                        "text-14 bg-red-50 p-[5px] rounded-[5px]",
                      redirectionFilter: "&IsInTransitCritical=true",
                    },
                  ]}
                  wordToAdd="critical"
                />
              }
            />
            <Route
              path="/in-transit-list"
              element={<InTransitListComponent />}
            />
            <Route
              path="/exception-summary"
              element={
                <ReconciliationSummary
                  ReconciliationSummaryTitle="Exception Summary"
                  ReconciliationSummaryTag="EXCEPTION"
                  redirectionURL="/exception-records"
                  headers={[
                    {
                      label: "Total Records",
                      mapping: "EXCEPTIONS_RECORDS",
                      amountClassName:
                        "text-14 bg-gray-100 p-[5px] rounded-[5px]",
                    },
                    {
                      label: "Aging Exceptions",
                      mapping: "AGING_EXCEPTIONS",
                      className: "text-red-400",
                      amountClassName:
                        "text-14 bg-red-50 p-[5px] rounded-[5px]",
                      redirectionFilter:
                        "&AgingTierTag=EXCEPTION_LOW_AGING_THRESHOLD_IN_HOURS,EXCEPTION_MODERATE_AGING_THRESHOLD_IN_HOURS,EXCEPTION_CRITICAL_AGING_THRESHOLD_IN_HOURS",
                    },
                  ]}
                  wordToAdd="aging"
                />
              }
            />
            <Route
              path="/exception-records"
              element={<ExceptionsRecordComponent />}
            />
            <Route
              path="/exception-attempt-details"
              element={
                <AttemptDetails
                  sentActivityTag="GetExceptionAttempts"
                  from="exception"
                />
              }
            />
            <Route
              path="/inTransit-attempt-details"
              element={
                <AttemptDetails
                  sentActivityTag="GetInTransitAttempts"
                  from="transit"
                />
              }
            />
            <Route
              path="/run-matching-records"
              element={<RunMatchingRecords />}
            />
            <Route
              path="/exception-attempt-matching"
              element={<AttemptMatching />}
            />
          </Routes>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={i18n.language === "ar"}
        pauseOnFocusLoss
        pauseOnHover
       />
      <SfmModal />
      <InfoModal />
      <DownloadModal />
    </BrowserRouter>
  );
};

const PageChangeHandler = ({ onPageChange }) => {
  usePageChange(onPageChange);
  return null;
};

export default App;
