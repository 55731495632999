import { makeObservable, observable, action } from "mobx";
class GlobalStore {
  timeZone = "Asia/Riyadh";
  ttpTenantCode = "2222";
  ttpUserId = "2222_USER_1";
  languageCode = "en";

  constructor() {
    makeObservable(this, {
      timeZone: observable,
      ttpTenantCode: observable,
      ttpUserId: observable,
      languageCode: observable,
      setGlobalSettings: action,
    });
  }

  isLoggedIn() {
    return this.ttpUserId !== "";
  }

  setGlobalSettings({
    timeZone = "Asia/Riyadh",
    ttpTenantCode = "2222",
    ttpUserId = "2222_USER_1",
    languageCode = "en",
  }) {
    this.timeZone = timeZone;
    this.ttpTenantCode = ttpTenantCode;
    this.ttpUserId = ttpUserId;
    this.languageCode = languageCode;
  }
}

const myStore = new GlobalStore();

export default myStore;
