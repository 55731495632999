import { observable, action, makeObservable } from "mobx";

class InfoModalStore {
  showing = false;
  title = "";
  type = "";
  data = null;
  afterClose = null;

  constructor() {
    makeObservable(this, {
      showing: observable,
      title: observable,
      type: observable,
      data: observable,
      afterClose: observable,
      open: observable,
      close: action,
    });
  }

  open(modalObject, afterClose = null) {
    if (!this.showing) {
      if (modalObject.show) {
        this.showing = modalObject.show;
        this.title = modalObject.title;
        this.type = modalObject.type;
        this.data = modalObject.data
        this.afterClose = afterClose;
      }
    }
  }

  close() {
    this.showing = false;
    this.title = "";
    this.type = "";
    this.data = null;
    this.afterClose && this.afterClose();
  }
}

const store = new InfoModalStore();
export default store;
