import React from "react";
import Lottie from "lottie-react";
import low from "@assets/lottie/low.json";
import medium from "@assets/lottie/medium.json";
import high from "@assets/lottie/high.json";
import success from "@assets/lottie/success.json";
import { Observer } from "mobx-react";
import SfmModalStore from "@stores/SfmModalStore";

const SfmModal = () => {
  const getIcons = (type) => {
    let animationData = "";
    switch (type) {
      case "error":
        animationData = high;
        break;
      case "success":
        animationData = success;
        break;
      case "info":
        animationData = low;
        break;
      case "warning":
        animationData = medium;
        break;
      default:
        return null;
    }
    return (
      <div className="m-auto">
        <Lottie
          animationData={animationData}
          loop={false}
          style={{ width: "120px", height: "120px" }}
        />
      </div>
    );
  };

  return (
    <Observer>
      {() =>
        SfmModalStore.showing && (
          <>
            <div
              className="fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-40 z-[125]"
              onClick={() => SfmModalStore.close()}
            />
            <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  z-[125] bg-white text-black py-6 px-5">
              <div className="flex flex-col gap-3">
                <button
                  onClick={() => SfmModalStore.close()}
                  className="w-full justify-end flex"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 hover:text-red-800 transition-all duration-200"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <div className="flex flex-col justify-center gap-3 items-center p-5 text-lg">
                  {getIcons(SfmModalStore.type)}
                  {SfmModalStore.message}
                </div>
              </div>
            </div>
          </>
        )
      }
    </Observer>
  );
};

export default SfmModal;
