import React, { createRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ButtonComponent from "@components/ButtonComponent";
import clsx from "clsx";

const ActionCell = ({
  row,
  toggleSubMenu,
  subMenuID,
  actionFunctions,
  actions,
  hideActionOnRow,
  store,
}) => {
  const { i18n } = useTranslation();
  const parentRef = useRef(null);
  const childRef = useRef(null);

  useEffect(() => {
    if (subMenuID !== null) {
      document.body.style.overflow = "hidden";
      document.querySelector(".main-table-container").style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      document.querySelector(".main-table-container").style.overflow = "auto";
    }
  }, [subMenuID]);

  const handleClick = () => {
    toggleSubMenu && toggleSubMenu();
  };

  useEffect(() => {
    if (parentRef.current && childRef.current && row.id === subMenuID) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const top = parentRect.top;
      const right = window.innerWidth - parentRect.right;

      let TOP_OFFSET = 50;
      let RIGHT_OFFSET = -1;

      if (i18n.language === "ar") {
        RIGHT_OFFSET = -1 * childRef.current.offsetWidth;
        TOP_OFFSET = 15;
      }

      childRef.current.style.top = `${top + TOP_OFFSET}px`;
      childRef.current.style.right = `${right + RIGHT_OFFSET}px`;

      childRef.current.style.opacity = 1;

    }
  }, [parentRef, childRef]);

  return (
    (!hideActionOnRow || !hideActionOnRow(row)) && (
      <div>
        <div
          key={`actionCell_${row.id}`}
          className="flex flex-col gap-2 justify-around overflow-auto"
        >
          <button
            id={`parent_${row.id}`}
            ref={parentRef}
            onClick={() => handleClick()}
            className="relative font-serif ml-0 px-0 py-1 bg-transparent text-blue-500 rounded"
          >
            ...
          </button>
          {row.id === subMenuID && subMenuID !== null && (
            <div
              id={`child_${row.id}`}
              ref={childRef}
              className={clsx(
                `fixed flex flex-col transition-opacity duration-400 opacity-0 -translate-y-full top-10 right-3 justify-around w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 z-[25]`
              )}
            >
              {row.original.Actions?.map((actionIdentifier, index) => {
                const action = actions[actionIdentifier];
                return (
                  !!action && (
                    <ButtonComponent
                      key={index}
                      type="subButton"
                      align="start"
                      withoutBorder={true}
                      onClick={() => {
                        actionFunctions(action, row);
                      }}
                      text={action.name}
                      className="justify-end"
                    />
                  )
                );
              })}
            </div>
          )}
        </div>
      </div>
    )
  );
};
export default ActionCell;
