import { makeObservable, observable, action } from "mobx";
import PageStore from "./PageStore";

const DEFAULT_LIMIT = 5;

class ListingStore {
  limit = DEFAULT_LIMIT;
  offset = 0;
  total = 0;
  page = 0;
  data = [];
  dataBeforeFilter = []; // this will be used for FE Search
  orderByKey = "id";
  orderByDirection = "asc";
  filter = [];
  sort = [];
  loading = true;
  fromFilter = false;
  showMore = false;
  dataKey = null;
  hasLessData = false;

  constructor() {
    makeObservable(this, {
      limit: observable,
      offset: observable,
      total: observable,
      page: observable,
      data: observable,
      dataBeforeFilter: observable,
      orderByKey: observable,
      orderByDirection: observable,
      filter: observable,
      loading: observable,
      fromFilter: observable,
      showMore: observable,
      dataKey: observable,
      hasLessData: observable,
      fillData: action,
      OLDfillData: action,
      getData: action,
      deepFind: action,
      handleOrderByChange: action,
      handleOrderByData: action,
      handleChangePage: action,
      handleChangeLimit: action,
      handleFilter: action,
      getDataFromFrontendSearch: action,
      refreshData: action,
    });
  }

  fillData(
    fromFilter = false,
    callback = null,
    overrideData = false,
    hideLoading = false
  ) {
    PageStore.disableRefreshAction = true;
    this.loading = !hideLoading;
    this.fromFilter = fromFilter;

    this.getData().then((response) => {
      if (overrideData) {
        this.data = [];
      }

      if (
        response &&
        response.data &&
        Array.isArray(response.data) &&
        Array.isArray(this.data.slice())
      ) {
        if (response.data.length > 0) {
          if (this.showMore) {
            this.data = this.data.concat(response.data);
          } else {
            this.data = response.data;
          }
        } else {
          this.showMore = false;
        }
      } else if (response && response.data && Array.isArray(response.data)) {
        if (response.data.length > 0) {
          this.data = response.data;
        } else {
          this.showMore = false;
        }
      } else if (response && this.dataKey && response[this.dataKey]) {
        // const hasSubData = this.dataKey && response.data[this.dataKey] && response.data[this.dataKey].length > 0
        // if (hasSubData) {
        //     const newDataSize = response.data[this.dataKey].length

        //     if (this.data[this.dataKey] && Array.isArray(this.data[this.dataKey])) {
        //         this.data[this.dataKey] = this.data[this.dataKey].concat(response.data[this.dataKey])
        //     } else {
        //         this.data[this.dataKey] = response.data[this.dataKey]
        //     }

        //     this.hasLessData = newDataSize < this.limit ? false : true
        //     this.showMore = true
        // } else {
        // if (this.dataKey && response[this.dataKey]){
        //   this.data = this.data.concat(response[this.dataKey]);
        // }
        // if (overrideData || fromFilter){
        //   if (this.dataKey && response[this.dataKey]) {
        //     this.data = response[this.dataKey];
        //   }
        // }else {
        //   if (this.dataKey && response[this.dataKey]) {
        //     if (this.data.length > 0) {
        //       this.data = this.data.concat(response[this.dataKey]);
        //     } else{
        //       this.data = response[this.dataKey];
        //     }
        //   }
        // }

        this.showMore = false;
        // }
      } else {
        this.showMore = false;
      }

      if (response && response.total) {
        this.total = response.total;
      } else {
        this.total = 0;
      }
      if (!fromFilter) this.dataBeforeFilter = this.data;
      this.page = this.offset / this.limit;
      this.loading = false;
      PageStore.disableRefreshAction = false;
      callback && callback(response);
    });
  }

  OLDfillData(fromFilter = false, callback = null) {
    this.loading = true;
    this.fromFilter = fromFilter;

    this.getData().then((response) => {
      this.data = {};
      if (response && response.data) {
        this.data = response.data;
      }

      if (response && response.total) {
        this.total = response.total;
      } else {
        this.total = 0;
      }

      if (!fromFilter) this.dataBeforeFilter = this.data;
      this.page = this.offset / this.limit;
      this.loading = false;

      callback && callback();
    });
  }

  getData() {
    // should override this
    alert("REPLACE GET DATA FUNCTION");
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  }

  deepFind(obj, path) {
    for (var i = 0, path = path.split("."), len = path.length; i < len; i++) {
      obj = obj[path[i]];
    }
    return obj;
  }

  handleOrderByChange(orderByKey, orderByDirection) {
    this.offset = 0; // to reset data
    this.orderByKey = orderByKey;
    this.orderByDirection = orderByDirection;
    this.handleOrderByData(orderByKey);
  }

  handleOrderByData(orderByKey) {
    let direction = this.orderByDirection;
    if (direction === undefined) direction = "asc";

    let sorted = this.data.sort(function (a, b) {
      var partA = "";
      var partB = "";

      if (
        a !== undefined &&
        a.hasOwnProperty(orderByKey) &&
        a[orderByKey] !== undefined &&
        a[orderByKey] !== null
      )
        partA = a[orderByKey].toLowerCase();

      if (
        b !== undefined &&
        b.hasOwnProperty(orderByKey) &&
        b[orderByKey] !== undefined &&
        b[orderByKey] !== null
      )
        partB = b[orderByKey].toLowerCase();

      if (partA < partB) {
        if (direction === "desc") return 1;
        else return -1;
      }
      if (partA > partB) {
        if (direction === "desc") return -1;
        else return 1;
      }
      return 0;
    });
    this.data = sorted;
  }

  handleChangePage(page) {
    this.offset = this.limit * page;
    this.fillData();
  }

  getNextPage(callback) {
    this.offset = this.offset + 1;
    this.fillData(false, callback, false, true);
  }

  handleChangeLimit(limit) {
    this.offset = 0;
    this.limit = limit;
    this.fillData();
  }

  setAppliedFilters(
    filters = {},
    fillData = true,
    callback = null,
    overrideData = false
  ) {
    const storeFilters = [];
    Object.keys(filters)?.map((key) => {
      const singleSelectedFilter = filters[key];

      if (Array.isArray(singleSelectedFilter.value)) {
        storeFilters.push({
          ColumnName: key,
          Value: singleSelectedFilter.value.join("|"),
          Operand: "EQ",
        });
      } else {
        if (singleSelectedFilter.filterType === "date") {
          const hasRange = singleSelectedFilter.value.split("|").length > 1;
          storeFilters.push({
            ColumnName: key,
            Value: `${
              hasRange
                ? singleSelectedFilter.value.split("|")[0]
                : singleSelectedFilter.value
            }T00:00:00`,
            Operand: "GTE",
          });
          storeFilters.push({
            ColumnName: key,
            Value: `${
              hasRange
                ? singleSelectedFilter.value.split("|")[1]
                : singleSelectedFilter.value
            }T23:59:59.999`,
            Operand: "LTE",
          });
        } else if (singleSelectedFilter.filterType === "checkbox") {
          const valueToSend = singleSelectedFilter.value.split(",").join("|");
          storeFilters.push({
            ColumnName: key,
            Value: valueToSend,
            Operand: singleSelectedFilter.operator,
          });
        } else if (singleSelectedFilter.filterType === "boolean") {
          const valueToSend = singleSelectedFilter.value.toString();
          storeFilters.push({
            ColumnName: key,
            Value: valueToSend,
            Operand: singleSelectedFilter.operator,
          });
        } else if (singleSelectedFilter.filterType === "integer") {
          const valueToSend = parseInt(singleSelectedFilter.value);
          storeFilters.push({
            ColumnName: key,
            Value: valueToSend,
            Operand: singleSelectedFilter.operator,
          });
        } else {
          storeFilters.push({
            ColumnName: key,
            Value: singleSelectedFilter.value,
            Operand: singleSelectedFilter.operator,
          });
        }
      }
      return singleSelectedFilter;
    });
    this.filter = storeFilters.length > 0 ? [storeFilters] : null;
    this.offset = 0;
    this.data = [];
    if (fillData) {
      this.fillData(true, callback, overrideData);
    }
  }

  setAppliedSort(sort, fillData = true) {
    const storeSort = [];
    sort.map((singleSort, index) => {
      storeSort.push({
        ColumnName: singleSort.id,
        SortingLevel: index,
        SortingOrder: singleSort.direction.toUpperCase(),
      });
    });
    this.sort = storeSort;
    this.offset = 0;
    if (fillData) {
      this.fillData(true);
    }
  }

  handleFilter(key, value, type) {
    let index = this.filter.findIndex((item) => item.key === key);

    if (index > -1) {
      this.filter[index] = { key, value, type };
    } else {
      this.filter.push({ key, value, type });
    }

    this.offset = 0;
    this.fillData(true);
  }

  getDataFromFrontendSearch(colors = {}) {
    let { filter } = this;
    let dataAfterFilter = this.dataBeforeFilter;

    filter.forEach((filterItem) => {
      if (filterItem.type === "color") {
        let colorsObject = {};
        let availableData = [];

        Object.keys(colors).forEach((key) => {
          if (key !== "ranks") {
            if (!colorsObject[colors[key]]) colorsObject[colors[key]] = [];
            colorsObject[colors[key]].push(key);
          }
        });

        if (typeof filterItem.value !== "string") {
          if (filterItem.value.length === 0) {
            Object.keys(colorsObject).forEach((key) => {
              availableData.push(colorsObject[key]);
            });
          } else {
            filterItem.value.forEach((value) => {
              availableData.push(colorsObject[value]);
            });
          }
          availableData = availableData.flat();

          dataAfterFilter = dataAfterFilter.filter((item) =>
            availableData.includes(item[filterItem.key])
          );
        }
      } else {
        dataAfterFilter = dataAfterFilter.filter((item) => {
          let value = null;
          filterItem.key.split(".").forEach((key) => {
            if (!value) {
              value = item[key];
            } else {
              value = value[key];
            }
          });

          return value.toLowerCase().includes(filterItem.value.toLowerCase());
        });
      }
    });

    return dataAfterFilter;
  }

  refreshData(
    enableShowMore = true,
    refreshDataAfterLoad = false,
    callback = null,
    overrideData = false
  ) {
    this.offset = 0;
    this.total = 0;
    this.page = 0;
    if (!refreshDataAfterLoad) {
      this.data = [];
    }
    this.loading = true;
    this.showMore = enableShowMore;
    this.fillData(false, callback, overrideData);
  }
}

export default ListingStore;
