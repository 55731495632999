import React from "react"
export default [
    {
      title: "dashboard",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 64 64"
        >
          <circle
            cx="32"
            cy="32"
            r="28"
            fill="#2196f3"
            stroke="#1e88e5"
            strokeWidth="4"
          />

          <path
            d="M16,32 A16,16 0 0,1 48,32"
            fill="none"
            stroke="#ffffff"
            strokeWidth="8"
          />

          <path d="M32,32 L32,12" stroke="#ffffff" strokeWidth="4" />
          <circle cx="32" cy="32" r="4" fill="#ffffff" />

          <rect x="10" y="40" width="12" height="12" fill="#ffffff" />
          <rect x="26" y="40" width="12" height="12" fill="#ffffff" />
          <rect x="42" y="40" width="12" height="12" fill="#ffffff" />
        </svg>
      ),
      path: "/",
    },
    {
      title: "acquisitions",
      tinyTitle: "",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 64 64"
        >
          <rect x="14" y="36" width="36" height="8" fill="#3f51b5" />
          <rect x="18" y="28" width="36" height="8" fill="#5c6bc0" />
          <rect x="22" y="20" width="36" height="8" fill="#7986cb" />
        </svg>
      ),
      path: "/data-acquisitions",
      gap: true,
    },
    {
      title: "acquisition-instances",
      tinyTitle: "",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 64 64"
        >
          <rect x="14" y="36" width="36" height="8" fill="#3f51b5" />
          <rect x="18" y="28" width="36" height="8" fill="#5c6bc0" />
          <rect x="22" y="20" width="36" height="8" fill="#7986cb" />

          <polygon
            points="32,0 48,16 40,16 40,56 24,56 24,16 16,16"
            fill="#ff9800"
          />
        </svg>
      ),
      path: "/data-acquisitions-instances",
    },
    {
      title: "streams",
      tinyTitle: "",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 64 64"
        >
          <path
            d="M16,12 C16,30 16,30 32,30"
            stroke="#4caf50"
            strokeWidth="6"
            fill="none"
          />
          <path
            d="M48,12 C48,30 48,30 32,30"
            stroke="#4caf50"
            strokeWidth="6"
            fill="none"
          />

          <circle cx="32" cy="30" r="6" fill="#ff5722" />

          <path
            d="M32,30 L32,52"
            stroke="#ff5722"
            strokeWidth="6"
            fill="none"
          />
        </svg>
      ),

      path: "/reconciliation-streams",
      gap: true,
    },
    {
      title: "runs",
      tinyTitle: "",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 64 64"
        >
          <path
            d="M32,4 A28,28 0 1,1 4,32"
            fill="none"
            stroke="#2196f3"
            strokeWidth="6"
          />
           <polygon points="10,32 18,26 18,38" fill="#2196f3" />
          <path
            d="M32,60 A28,28 0 1,1 60,32"
            fill="none"
            stroke="#ff5722"
            strokeWidth="6"
          />
            <polygon points="54,32 46,26 46,38" fill="#ff5722" />
        </svg>
      ),
      path: "/reconciliation-runs",
    },
    {
      title: "in-transit",
      tinyTitle: "",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 64 64"
        >
          <rect
            x="16"
            y="16"
            width="28"
            height="36"
            rx="4"
            ry="4"
            fill="#4caf50"
          />
          <path d="M44,24 H24" stroke="#ffffff" strokeWidth="4" fill="none" />
          <path d="M44,32 H24" stroke="#ffffff" strokeWidth="4" fill="none" />
          <path d="M44,40 H24" stroke="#ffffff" strokeWidth="4" fill="none" />

          <path
            d="M44,16 L60,32 L44,48"
            stroke="#ff9800"
            strokeWidth="6"
            fill="none"
          />
          <polygon points="54,32 60,26 60,38" fill="#ff9800" />
        </svg>
      ),
      path: "/in-transit-summary",
      gap: true,
    },
    {
      title: "exception",
      tinyTitle: "",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 64 64"
        >
          <rect
            x="12"
            y="12"
            width="40"
            height="52"
            rx="4"
            ry="4"
            fill="#f44336"
          />
          <path d="M40,16 H24" stroke="#ffffff" strokeWidth="4" fill="none" />
          <path d="M40,24 H24" stroke="#ffffff" strokeWidth="4" fill="none" />
          <path d="M40,32 H24" stroke="#ffffff" strokeWidth="4" fill="none" />
          <circle cx="32" cy="48" r="6" fill="#ffffff" />
          <rect x="30" y="32" width="4" height="12" fill="#ffffff" />
        </svg>
      ),
      path: "/exception-summary",
    },
  ]