import React, { useEffect, useState } from "react";
import displayValues from "./displayValues";
import TailwindDropdown from "@components/TailwindDropdown";
import { RoundToTwoDecimals } from "@helpers/helpers";
import clsx from "clsx";

const InfoComponent = ({ data, hideKeys = [], globalParent = "" }) => {
  useEffect(() => {
    if (!globalParent) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      if (!globalParent) {
        document.body.style.overflow = "auto";
      }
    };
  }, []);

  const transformData = (data) => {
    const result = {};

    for (const [key, value] of Object.entries(data)) {
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        value === null
      ) {
        if (!result.basicInfo) {
          result.basicInfo = {};
        }
        result.basicInfo[key] = value;
      } else {
        result[key] = value;
      }
    }
    return result;
  };
  const renderValue = (value, key) => {
    const displayProperty = displayValues[key] || key;
    const identifier = `${globalParent && `/${globalParent}`}/${key}`;
    if (hideKeys.includes(identifier) || value === null || value === undefined || value === "") return null;
    if (typeof value === "string" || typeof value === "number") {
      return (
        <div className="flex flex-col gap-1">
          <div className="font-semibold text-sm">{displayProperty}</div>
          <div className="text-sm">
            {typeof value === "number" ? RoundToTwoDecimals(value) : value}
          </div>
        </div>
      );
    } else if (Array.isArray(value)) {
      return (
        <Tabs
          data={value}
          keyName={key}
          hideKeys={hideKeys}
          parent={key}
          title={displayProperty}
        />
      );
    } else if (typeof value === "object") {
      return (
        <SubSection
          data={value}
          title={displayProperty}
          hideKeys={hideKeys}
          parent={key}
        />
      );
    }
  };

  return (
    <div
      className={clsx(
        globalParent ? "mt-4" : "infoContainer",
        `flex flex-col gap-4 w-full `
      )}
    >
      {(globalParent
        ? Object.entries(data)
        : Object.entries(transformData(data))
      ).map(([key, value]) => renderValue(value, key))}
    </div>
  );
};

const SubSection = ({ data, title, hideKeys, parent }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`border-2 border-gray-200 p-6 flex flex-col`}>
      <button
        className="flex justify-between cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="font-bold text-xl">{title}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={clsx(
            `size-6 transition-all duration-500`,
            !isOpen && "rotate-180"
          )}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
      <div
        className={clsx(
          `grid grid-rows-[0fr]  transition-all ease-in-out duration-[1000ms]`,
          isOpen ? "grid-rows-[1fr]" : ""
        )}
      >
        <div className={`overflow-hidden`}>
          <InfoComponent
            data={data}
            hideKeys={hideKeys}
            globalParent={parent}
          />
        </div>
      </div>
    </div>
  );
};

const Tabs = ({ data, keyName, hideKeys, parent, title }) => {
  const [activeTab, setActiveTab] = useState(data.length - 1);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-2 border-gray-200 p-6 flex flex-col">
      <button
        className="flex justify-between cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="font-bold text-xl">{title}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={clsx(
            `size-6 transition-all duration-500`,
            !isOpen && "rotate-180"
          )}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
      <div
        className={clsx(
          `grid grid-rows-[0fr]  transition-all ease-in-out duration-[1000ms]`,
          isOpen ? "grid-rows-[1fr]" : ""
        )}
      >
        <div className={`overflow-hidden`}>
          <div className="flex">
            <TailwindDropdown
              data={data.map((item, index) => {
                return {
                  key: index,
                  value: `Revocation ${index + 1} (${item.StartDate})`,
                  // value: keyName + " " + (index + 1),
                };
              })}
              selectedValue={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div>
            <InfoComponent
              data={data[activeTab]}
              hideKeys={hideKeys}
              globalParent={parent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoComponent;
