import { makeObservable, observable, action } from "mobx";

class PageStore {
    title = "";
    addAction = null;
    disableAddAction = false;
    backAction = null;
    refreshAction = null;
    isTxPayTheme = null;
    disableRefreshAction = false;

    constructor() {
        makeObservable(this, {
            title: observable,
            addAction: observable,
            disableAddAction: observable,
            backAction: observable,
            refreshAction: observable,
            isTxPayTheme: observable,
            disableRefreshAction: observable,
            pageOpened: action
        });
    }

    pageOpened({ title = "", addAction = null, backAction = null, refreshAction = null, disableRefreshAction = false, disableAddAction = false }) {
        this.title = title;
        this.addAction = addAction;
        this.disableAddAction = disableAddAction;
        this.backAction = backAction;
        this.refreshAction = refreshAction;
        this.disableRefreshAction = disableRefreshAction;
    }
}

const myStore = new PageStore();

export default myStore;